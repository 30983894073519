import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    [
      _c(VCardTitle, { attrs: { "primary-title": "" } }, [
        _c("div", { staticClass: "H5-Primary-Left" }, [
          _vm._v("Loyalty Program")
        ])
      ]),
      !_vm.value.enabled
        ? _c(
            "div",
            [
              _c(
                VLayout,
                {
                  attrs: {
                    column: "",
                    "justify-center": "",
                    "align-center": "",
                    "fill-height": ""
                  }
                },
                [
                  _c(VImg, {
                    attrs: {
                      src: require("@/assets/loyalty.svg"),
                      height: "145px",
                      width: "187px"
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "Body-1-Black-High-Emphasis-Center Loyalty-Description-Messages"
                    },
                    [
                      _vm._v(
                        "\n        Loyalty is not enabled for this brand\n      "
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "Subtitle-1-Black-Medium-Emphasis-Center Loyalty-Description-Messages"
                    },
                    [
                      _vm._v(
                        "\n        Would you like to enable Loyalty?\n      "
                      )
                    ]
                  ),
                  _c("toggle", {
                    attrs: { disabled: _vm.isSectionDisabled },
                    model: {
                      value: _vm.value.enabled,
                      callback: function($$v) {
                        _vm.$set(_vm.value, "enabled", $$v)
                      },
                      expression: "value.enabled"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "Loyalty-Content",
              staticStyle: { padding: "24px", "padding-top": "0px" }
            },
            [
              _c(
                VLayout,
                [
                  _c(
                    VFlex,
                    [
                      _c(VTextField, {
                        staticClass:
                          "Text-Field-Filled-Trailing-Icon-Pressed mb-3",
                        attrs: {
                          label: "Program ID",
                          rules: _vm.providerIdRules,
                          disabled: _vm.isSectionDisabled
                        },
                        model: {
                          value: _vm.value.provider_id,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "provider_id", $$v)
                          },
                          expression: "value.provider_id"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    [
                      _c(
                        VLayout,
                        { attrs: { "justify-end": "" } },
                        [
                          _c(
                            VBtn,
                            {
                              staticClass: "Remove-Button mt-4 mr-3",
                              attrs: {
                                color: "primary",
                                disabled: _vm.isSectionDisabled
                              },
                              on: {
                                click: function($event) {
                                  _vm.showRemoveDialog = true
                                }
                              }
                            },
                            [
                              _c(
                                VIcon,
                                { staticClass: "mr-1", attrs: { left: "" } },
                                [_vm._v("mdi-delete-outline")]
                              ),
                              _vm._v("REMOVE\n          ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.type === _vm.SITE
                ? _c(
                    VLayout,
                    {
                      staticClass: "Loyalty-Emails-Content mt-3 mb-5",
                      attrs: { "row-wrap": "" }
                    },
                    [
                      _c(VFlex, [
                        !_vm.isSectionDisabled
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "Body-1-Primary-Left Loyalty-Emails-Link",
                                on: {
                                  click: function($event) {
                                    _vm.showEmailDialog = true
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n          Manage Loyalty Emails\n        "
                                )
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "Caption-Black-Medium-Emphasis-Left" },
                          [
                            _vm._v(
                              "\n          Loyalty Access:\n          " +
                                _vm._s(
                                  _vm.numUsers === 0
                                    ? "Entire Site"
                                    : _vm.numUsers +
                                        " User" +
                                        (_vm.numUsers > 1 ? "s" : "")
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
      _vm.showEmailDialog
        ? _c(
            VDialog,
            {
              attrs: { persistent: "", width: "800px", height: "600px" },
              model: {
                value: _vm.dialogVModelPlaceHolder,
                callback: function($$v) {
                  _vm.dialogVModelPlaceHolder = $$v
                },
                expression: "dialogVModelPlaceHolder"
              }
            },
            [
              _c(
                VCard,
                [
                  _c(VCardTitle, { staticClass: "H4-Secondary-Center" }, [
                    _vm._v("Manage Loyalty Users")
                  ]),
                  _c(
                    VForm,
                    { ref: "emailForm", staticClass: "Email-Dialog-Content" },
                    [
                      _c(
                        VLayout,
                        [
                          _c(
                            VFlex,
                            { attrs: { grow: "" } },
                            [
                              _c(VTextField, {
                                staticClass: "mb-2",
                                attrs: {
                                  label: "User Email",
                                  rules: _vm.emailRules
                                },
                                on: {
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.addEmail($event)
                                  }
                                },
                                model: {
                                  value: _vm.newEmail,
                                  callback: function($$v) {
                                    _vm.newEmail = $$v
                                  },
                                  expression: "newEmail"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            VFlex,
                            { attrs: { shrink: "", "py-2": "" } },
                            [
                              _c(
                                VLayout,
                                { attrs: { "justify-end": "" } },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      attrs: { dark: "", color: "#0c62c6" },
                                      on: { click: _vm.addEmail }
                                    },
                                    [_vm._v("ADD")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("div", { staticClass: "Email-Dialog-List" }, [
                        _vm.newEmailList.length > 0
                          ? _c(
                              "div",
                              _vm._l(_vm.newEmailList, function(l, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "Email-Cell" },
                                  [
                                    _c(
                                      VLayout,
                                      {
                                        staticClass: "Body-1 pl-2",
                                        attrs: {
                                          row: "",
                                          wrap: "",
                                          "align-center": ""
                                        }
                                      },
                                      [
                                        _c(VFlex, [_vm._v(_vm._s(l.email))]),
                                        _c(
                                          VFlex,
                                          [
                                            _c(
                                              VLayout,
                                              { attrs: { "justify-end": "" } },
                                              [
                                                _c(
                                                  VBtn,
                                                  {
                                                    attrs: {
                                                      flat: "",
                                                      color: "grey"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.newEmailList.splice(
                                                          index,
                                                          1
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      VIcon,
                                                      {
                                                        staticClass: "mr-1",
                                                        attrs: { left: "" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-delete-outline"
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      "REMOVE\n                    "
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _c(
                              "div",
                              { staticClass: "mt-4" },
                              [
                                _c(
                                  VLayout,
                                  {
                                    attrs: {
                                      column: "",
                                      "justify-center": "",
                                      "align-center": "",
                                      "fill-height": ""
                                    }
                                  },
                                  [
                                    _c(VImg, {
                                      attrs: {
                                        src: require("@/assets/loyalty-email.svg"),
                                        height: "158px",
                                        width: "179px"
                                      }
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "Body-1-Black-High-Emphasis-Center Loyalty-Description-Messages"
                                      },
                                      [
                                        _vm._v(
                                          "\n                Enter emails to allow on certain users\n              "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "Subtitle-1-Black-Medium-Emphasis-Center Loyalty-Description-Messages"
                                      },
                                      [
                                        _vm._v(
                                          "\n                to access the on-site loyalty program\n              "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                      ]),
                      _c(
                        VCardActions,
                        [
                          _c(VSpacer),
                          _c(
                            VBtn,
                            {
                              attrs: { flat: "", color: "blue" },
                              on: {
                                click: function($event) {
                                  return _vm.cancelEmails()
                                }
                              }
                            },
                            [_vm._v("CANCEL")]
                          ),
                          _c(
                            VBtn,
                            {
                              attrs: { flat: "", color: "blue" },
                              on: {
                                click: function($event) {
                                  return _vm.saveEmails()
                                }
                              }
                            },
                            [_vm._v("SAVE")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("remove-dialog", {
        attrs: {
          title: "Remove Loyalty Program",
          descriptionLines: [
            "Removing cannot be undone. Any users who are using the loyalty program will no longer have access to the program and will lose all their accumulated points.",
            "To continue with deletion please enter REMOVE in the field below and continue."
          ],
          onConfirmHandler: _vm.removeLoyalty
        },
        model: {
          value: _vm.showRemoveDialog,
          callback: function($$v) {
            _vm.showRemoveDialog = $$v
          },
          expression: "showRemoveDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }