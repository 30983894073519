<template>
  <v-dialog persistent v-model="value" width="580px" height="428px">
    <v-card>
      <v-card-title class="H4-Secondary-Center">{{ title }}</v-card-title>
      <div class="Remove-Dialog-Content">
        <div class="Body-1-Black-High-Emphasis-Left Remove-Dialog-Text">
          <div v-for="(d, i) in descriptionLines" v-bind:key="i">
            {{ d }}
            <div v-if="descriptionLines.length - 1 !== i"><br /><br /></div>
          </div>
        </div>
        <v-text-field
          v-model="confirmRemovalText"
          class="my-1 Text-Field-Outlined-Unfilled-Enabled"
          label="Confirm Removal"
        />
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat color="blue" v-on:click="cancel()">CANCEL</v-btn>
        <v-btn flat color="blue" :disabled="!validRemoval" v-on:click="confirm()">CONTINUE</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['value', 'title', 'descriptionLines', 'onConfirmHandler', 'onCancelHandler'],
  data: () => ({
    confirmRemovalText: null,
  }),
  computed: {
    validRemoval() {
      try {
        return this.confirmRemovalText === 'REMOVE';
      } catch (err) {
        return false;
      }
    },
  },
  methods: {
    cancel() {
      this.$emit('input', false);
      this.confirmRemovalText = null;
      if (this.onCancelHandler) this.onCancelHandler();
    },
    confirm() {
      this.$emit('input', false);
      this.confirmRemovalText = null;
      if (this.onConfirmHandler) this.onConfirmHandler();
    },
  },
};
</script>

<style>
.Remove-Dialog-Content {
  padding-left: 14px;
  padding-right: 14px;
}
.Remove-Dialog-Text {
  width: 100%;
  height: 210px;
}
</style>
