import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VDialog,
    {
      attrs: { persistent: "", width: "580px", height: "428px" },
      model: {
        value: _vm.value,
        callback: function($$v) {
          _vm.value = $$v
        },
        expression: "value"
      }
    },
    [
      _c(
        VCard,
        [
          _c(VCardTitle, { staticClass: "H4-Secondary-Center" }, [
            _vm._v(_vm._s(_vm.title))
          ]),
          _c(
            "div",
            { staticClass: "Remove-Dialog-Content" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "Body-1-Black-High-Emphasis-Left Remove-Dialog-Text"
                },
                _vm._l(_vm.descriptionLines, function(d, i) {
                  return _c("div", { key: i }, [
                    _vm._v("\n          " + _vm._s(d) + "\n          "),
                    _vm.descriptionLines.length - 1 !== i
                      ? _c("div", [_c("br"), _c("br")])
                      : _vm._e()
                  ])
                }),
                0
              ),
              _c(VTextField, {
                staticClass: "my-1 Text-Field-Outlined-Unfilled-Enabled",
                attrs: { label: "Confirm Removal" },
                model: {
                  value: _vm.confirmRemovalText,
                  callback: function($$v) {
                    _vm.confirmRemovalText = $$v
                  },
                  expression: "confirmRemovalText"
                }
              })
            ],
            1
          ),
          _c(
            VCardActions,
            [
              _c(VSpacer),
              _c(
                VBtn,
                {
                  attrs: { flat: "", color: "blue" },
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v("CANCEL")]
              ),
              _c(
                VBtn,
                {
                  attrs: {
                    flat: "",
                    color: "blue",
                    disabled: !_vm.validRemoval
                  },
                  on: {
                    click: function($event) {
                      return _vm.confirm()
                    }
                  }
                },
                [_vm._v("CONTINUE")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }