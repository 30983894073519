<template>
  <v-layout align-left class="tocLayout" :class="customClass">
    <v-list once-line class="tocStyle">
      <template v-for="item in items">
        <v-list-tile
          v-if="!isExcludedItem(item.id)"
          :key="item.title"
          @click="scrollToContent(item.id)"
          v-bind:class="{
            [style.itemSelected]: item.id === selectedValue,
            [style.itemNotSelected]: item.id !== selectedValue,
          }"
        >
          <v-list-tile-content>
            <v-list-tile-title
              v-html="item.title"
              v-bind:class="{
                [style.itemSelectedLeft]: item.id === selectedValue,
                [style.itemNotSelectedLeft]: item.id !== selectedValue,
              }"
            ></v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </template>
    </v-list>
  </v-layout>
</template>

<script>
export default {
  props: {
    customClass: {},
    items: {
      default: {},
    },
    excludedItems: {
      default: () => [],
    },
    isCashless: {
      default: false,
    },
  },
  data: () => ({
    style: {
      itemSelected: 'itemSelected',
      itemNotSelected: 'itemNotSelected',
      itemSelectedLeft: 'itemSelectedLeft',
      itemNotSelectedLeft: 'itemNotSelectedLeft',
    },
    selectedValue: '',
    selectedByClick: false,
  }),
  mounted() {
    this.initItems();
  },
  methods: {
    isExcludedItem(i) {
      return this.excludedItems.indexOf(i) >= 0;
    },
    async initItems() {
      if (this.items) {
        await this.items.map(async (item) => {
          item.selected = false;
        });
      }
      if (this.items && this.items.length > 0) {
        this.selectedValue = this.items[0].id;
      }
    },
    scrollToContent(id) {
      this.$vuetify.goTo(`#${id}`);
      this.selectedValue = id;
      this.selectedByClick = true;
      this.items.forEach((a) => {
        a.selected = false;
      });
    },
    visibilityChanged(isVisible, entry) {
      this.items.forEach((item) => {
        if (item.id === entry.target.id) {
          item.selected = isVisible;
          // this.selectedValue = item.selected ? item.id : '';
        }
      });
    },
  },
  watch: {
    isCashless: {
      handler(newVal) {
        // Can remove once we deprecate badge pay.
        let id;
        let title;
        if (newVal) {
          title = 'Cashless Pay';
          id = 'cashless-pay';
        } else {
          title = 'Badge Pay';
          id = 'badge-pay';
        }
        const newItems = this.items.map((item) => {
          if (item.title === 'Badge Pay' || item.title === 'Cashless Pay') {
            item.title = title;
            item.id = id;
            if (item.selected) {
              this.selectedValue = id;
            }
          }
          return item;
        });
        this.$emit('items', newItems);
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.tocLayout {
  position: relative;
  height: 100%;
}

.tocLayout.fixedStyle {
  height: auto;
  position: fixed;
  top: 5rem;
}

.tocStyle {
  position: absolute;
  top: 0;
  background: transparent;
  background-color: transparent;
}

.itemSelected {
  font-family: Avenir;
  font-size: 18.1px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.55;
  letter-spacing: 0.5px;
  color: #090c9b;
  background: transparent;
  background-color: transparent;
}
.itemNotSelected {
  font-family: Avenir;
  font-size: 18.1px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.55;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.6);
}

.itemSelectedLeft {
  border-left: 3px solid #090c9b;
  padding-left: 10px;
}

.itemNotSelectedLeft {
  border-left: 0;
  padding-left: 13px;
}
</style>
