import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VDialog,
    {
      attrs: { persistent: "", "max-width": "400" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        VCard,
        [
          _c(VCardTitle, { staticClass: "headline" }, [
            _vm._v("Select store")
          ]),
          _c(
            VCardText,
            [
              _c(
                VFlex,
                { attrs: { xs12: "", sm12: "", "d-flex": "" } },
                [
                  _c(
                    VForm,
                    { ref: "frictionlessForm" },
                    [
                      _c(VSelect, {
                        attrs: {
                          items: _vm.frictionlessStores,
                          "item-text": "name",
                          "item-value": "uuid",
                          rules: _vm.frictionlessRules
                        },
                        model: {
                          value: _vm.selectedStore,
                          callback: function($$v) {
                            _vm.selectedStore = $$v
                          },
                          expression: "selectedStore"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VCardActions,
            [
              _c(VSpacer),
              _c(
                VBtn,
                {
                  attrs: { color: "error darken-1", flat: "" },
                  on: { click: _vm.onClose }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                VBtn,
                {
                  attrs: { color: "success darken-1", flat: "" },
                  on: { click: _vm.updateStores }
                },
                [_vm._v("OK")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }