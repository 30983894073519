<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-text-field :disabled="isSectionDisabled" label="UU-ID" v-model="uuid" :rules="UUIDRules" />
    </v-flex>
    <v-flex v-if="this.mealplan.type === MEALPLAN_TYPES.CBORD" xs12>
      <v-text-field
        :disabled="isSectionDisabled"
        label="Auth URL"
        v-model="authUrl"
        :rules="authUrlRules"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import rules from '@/rules';
import { MEALPLAN_TYPES, TENDER_TYPES } from '@/constants';

export default {
  props: ['mealplan', 'isSectionDisabled'],
  data: () => ({
    MEALPLAN_TYPES,
    TENDER_TYPES,
    types: [
      {
        text: 'Cbord',
        value: MEALPLAN_TYPES.CBORD,
      },
      {
        text: 'CBORD DIRECT',
        value: MEALPLAN_TYPES.CBORD_DIRECT,
      },
    ],
    authUrlRules: [
      rules.required('Auth URL is a required field'),
      rules.url('Valid Auth URL is required'),
    ],
    UUIDRules: [rules.required('UU-ID is required')],
  }),
  computed: {
    uuid: {
      get() {
        return (
          this.mealplan.private &&
          this.mealplan.private.keystore &&
          this.mealplan.private.keystore.mealplan &&
          this.mealplan.private.keystore.mealplan.uuid
        );
      },
      set(v) {
        this.$set(this.mealplan.private.keystore.mealplan, 'uuid', v);
      },
    },
    authUrl: {
      get() {
        return (
          this.mealplan.private &&
          this.mealplan.private.keystore &&
          this.mealplan.private.keystore.mealplan &&
          this.mealplan.private.keystore.mealplan.auth_url
        );
      },
      set(v) {
        this.$set(this.mealplan.private.keystore.mealplan, 'auth_url', v);
      },
    },
  },
};
</script>
