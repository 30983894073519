<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <v-card>
      <v-card-title class="headline">Select store</v-card-title>
      <v-card-text>
        <v-flex xs12 sm12 d-flex>
          <v-form ref="frictionlessForm">
            <v-select
              :items="frictionlessStores"
              item-text="name"
              item-value="uuid"
              v-model="selectedStore"
              :rules="frictionlessRules"
            />
          </v-form>
        </v-flex>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" flat @click="onClose">Cancel</v-btn>
        <v-btn color="success darken-1" flat @click="updateStores">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import rules from '@/rules';

export default {
  props: ['dialog'],
  data: () => ({
    selectedStore: '',
    frictionlessRules: [rules.required('Store is required')],
  }),
  computed: {
    ...mapState('menus', ['frictionlessStores']),
  },
  methods: {
    async updateStores() {
      if (!this.$refs.frictionlessForm.validate()) {
        return;
      }
      this.$emit('submit', {
        frictionless_store_uuid: this.selectedStore,
      });
      this.clear();
    },
    onClose() {
      this.clear();
      this.$emit('close');
    },
    clear() {
      this.$refs.frictionlessForm.resetValidation();
      this.selectedStore = '';
    },
  },
};
</script>

<style></style>
