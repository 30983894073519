<template>
  <v-card>
    <v-card-title primary-title>
      <div class="H5-Primary-Left">Payment ID</div>
    </v-card-title>
    <div class="v-card-content">
      <v-layout row wrap>
        <v-flex xs8>
          <v-select
            :items="types"
            label="Payment Type"
            :value="selectedPayment"
            id="payment_payment_type_select"
            placeholder="Select Payment Type"
            v-on:input="selectPayment"
            :rules="requiredField"
            :disabled="isSectionDisabled"
          ></v-select>
        </v-flex>
        <v-flex x4>
          <v-checkbox
            v-model="showPaymentIds"
            hide-details
            on-icon="mdi-eye-off"
            off-icon="mdi-eye"
            @click.native.stop
            :false-value="false"
            :true-value="true"
            v-if="hasPermissions([customPermissions.viewPaymentInfo]) && !isEditing"
            :disabled="isSectionDisabled"
            title="Show payment IDs"
          />
        </v-flex>
      </v-layout>
      <v-form ref="form">
        <div style="color: #43a047" v-if="isPaymentConfigValidated && isPaymentConfigValid">
          Success validating payment config!
        </div>
        <div style="color: #FF5252" v-if="isPaymentConfigValidated && !isPaymentConfigValid">
          Error validating payment config!
        </div>
        <v-layout mb-2 row wrap v-if="payment.freedompay">
          <v-flex xs8>
            <v-text-field
              v-if="hasPermissions([customPermissions.viewPaymentInfo]) || isEditing"
              :value="freedompay_store_id"
              @input="
                freedompay_store_id = safeTrim($event);
                fieldUpdated();
              "
              label="Freedom Pay Store ID"
              :rules="requiredField"
              :disabled="isSectionDisabled || !isEditing"
              id="payment_freedom_pay_store_id_field"
              :type="paymentInputsType"
            />
            <v-text-field
              v-if="!hasPermissions([customPermissions.viewPaymentInfo]) && !isEditing"
              :value="'*********'"
              label="Freedom Pay Store ID"
              :disabled="true"
              id="payment_freedom_pay_store_id_field dummy_field"
              :type="paymentInputsType"
            />
          </v-flex>
          <v-flex xs8>
            <v-text-field
              v-if="hasPermissions([customPermissions.viewPaymentInfo]) || isEditing"
              :value="freedompay_terminal_id"
              @input="
                freedompay_terminal_id = safeTrim($event);
                fieldUpdated();
              "
              label="Freedom Pay Terminal ID"
              :rules="requiredField"
              id="payment_freedom_pay_terminal_id_field"
              :disabled="isSectionDisabled || !isEditing"
              :type="paymentInputsType"
              style="margin-bottom: 1em"
            />
            <v-text-field
              v-if="!hasPermissions([customPermissions.viewPaymentInfo]) && !isEditing"
              :value="'*********'"
              label="Freedom Pay Terminal ID"
              :disabled="true"
              id="payment_freedom_pay_terminal_id_field dummy_field"
              :type="paymentInputsType"
            />
          </v-flex>
          <v-flex xs4 />
        </v-layout>
      </v-form>
      <v-flex mt-1 xs12 v-if="selectedPayment && !isBrandConfig">
        <div class="Subtitle-1-Black-Medium-Emphasis-Left">Refunds Accepted?</div>
        <toggle
          @input="$emit('update:refund', $event)"
          :value="refund"
          :position="'start'"
          :disabled="isSectionDisabled"
        />
      </v-flex>
      <v-footer
        v-if="isAdmin"
        absolute
        height="60"
        style="background: white !important; !important;"
      >
        <v-layout v-if="payment.freedompay" row>
          <v-flex xs11></v-flex>
          <v-flex shrink>
            <v-btn v-if="!isEditing" flat color="blue" @click="editPaymentInfo">
              <v-icon style="margin-right: 6px;">mdi-pencil</v-icon>EDIT PAYMENT INFO
            </v-btn>
            <v-card-actions v-else row style="margin-right: 15px;">
              <v-btn color="gray" flat @click="validatePayment">
                <v-icon style="margin-left: 6px;">mdi-check</v-icon>validate
              </v-btn>
              <v-btn color="primary" flat @click="cancelEdit">cancel</v-btn>
              <v-btn :disabled="!isPaymentConfigValid" color="primary" @click="saveChanges"
                >save changes</v-btn
              >
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-footer>
    </div>
  </v-card>
</template>

<script>
// TODO: FIX THIS USING PROP.SYNC
import { mapGetters, mapState, mapActions } from 'vuex';
import rules from '@/rules';
import { safeTrim } from '@/helpers';

export default {
  props: {
    keystore: {},
    payment: {},
    isBrandConfig: {
      default: false,
    },
    isSectionDisabled: {
      type: Boolean,
      default: false,
    },
    refund: Boolean,
  },
  data: () => ({
    freedompay_store_id: '',
    freedompay_terminal_id: '',
    types: [{ text: 'FreedomPay', value: 'freedompay' }],
    requiredField: [rules.required('This is a required Field')],
    placeholder: 'placeholder',
    isEditing: false,
    backupValues: {
      freedompay_store_id: '',
      freedompay_terminal_id: '',
    },
    showPaymentIds: false,
    isPaymentConfigValidated: false,
    isPaymentConfigValid: false,
  }),
  computed: {
    ...mapGetters('adminPanel', ['isAdmin', 'hasPermissions']),
    ...mapState('users', ['customPermissions']),
    selectedPayment() {
      const { freedompay } = this.payment;
      return freedompay && 'freedompay';
    },
    paymentInputsType() {
      return this.isEditing || this.showPaymentIds ? 'text' : 'password';
    },
  },
  watch: {
    payment: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.freedompay?.freedompay_store_id && val.freedompay?.freedompay_terminal_id) {
          this.freedompay_store_id = val.freedompay?.freedompay_store_id;
          this.freedompay_terminal_id = val.freedompay?.freedompay_terminal_id;
        }
      },
    },
    keystore: {
      deep: true,
      immediate: true,
      handler(val) {
        if (!val.payment?.isEncrypted) {
          this.freedompay_store_id = val.payment?.freedompay?.freedompay_store_id;
          this.freedompay_terminal_id = val.payment?.freedompay?.freedompay_terminal_id;
        }
      },
    },
  },
  methods: {
    ...mapActions('sites', ['validatePaymentInfo']),
    selectPayment(p) {
      if (p === 'freedompay') {
        this.$emit('editPayment', {
          freedompay: {
            id: '9PGDGvzvrKfJ366ZBz09h2e0pr13RMSA9wAmerk4C1gJ3v15mO',
          },
          refund: false,
        });
        this.editPaymentInfo();
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      }
    },
    editPaymentInfo() {
      this.isPaymentConfigValidated = false;
      this.isPaymentConfigValid = false;
      this.isEditing = true;
      this.backupValues = {
        freedompay_store_id: this.freedompay_store_id,
        freedompay_terminal_id: this.freedompay_terminal_id,
      };

      this.freedompay_store_id = '';
      this.freedompay_terminal_id = '';
    },
    cancelEdit() {
      this.isEditing = false;
      this.isPaymentConfigValidated = false;
      this.isPaymentConfigValid = false;
      this.freedompay_store_id = this.backupValues.freedompay_store_id;
      this.freedompay_terminal_id = this.backupValues.freedompay_terminal_id;
    },
    async validatePayment() {
      if (this.$refs.form.validate()) {
        try {
          const response = await this.validatePaymentInfo({
            store_id: this.freedompay_store_id,
            terminal_id: this.freedompay_terminal_id,
          });
          this.isPaymentConfigValid = response.success;
        } catch (err) {
          console.error('payment validation error', err);
          this.isPaymentConfigValid = false;
        }

        this.isPaymentConfigValidated = true;
      }
    },
    fieldUpdated() {
      this.isPaymentConfigValidated = false;
      this.isPaymentConfigValid = false;
    },
    saveChanges() {
      if (this.$refs.form.validate()) {
        this.isEditing = false;
        this.isPaymentConfigValidated = false;
        this.isPaymentConfigValid = false;
        this.$emit('editKeystore', {
          payment: {
            freedompay: {
              freedompay_store_id: this.freedompay_store_id,
              freedompay_terminal_id: this.freedompay_terminal_id,
            },
          },
        });
      }
    },
    safeTrim,
  },
};
</script>
