import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    { attrs: { row: "", wrap: "" } },
    [
      _c(
        VFlex,
        { attrs: { xs12: "" } },
        [
          _c(VTextField, {
            attrs: {
              disabled: _vm.isSectionDisabled,
              label: "UU-ID",
              rules: _vm.UUIDRules
            },
            model: {
              value: _vm.uuid,
              callback: function($$v) {
                _vm.uuid = $$v
              },
              expression: "uuid"
            }
          })
        ],
        1
      ),
      this.mealplan.type === _vm.MEALPLAN_TYPES.CBORD
        ? _c(
            VFlex,
            { attrs: { xs12: "" } },
            [
              _c(VTextField, {
                attrs: {
                  disabled: _vm.isSectionDisabled,
                  label: "Auth URL",
                  rules: _vm.authUrlRules
                },
                model: {
                  value: _vm.authUrl,
                  callback: function($$v) {
                    _vm.authUrl = $$v
                  },
                  expression: "authUrl"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }