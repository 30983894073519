<template>
  <!-- Main card component for Frictionless settings -->
  <v-card>
    <v-card-title primary-title>
      <div class="H5-Primary-Left">Frictionless</div>
    </v-card-title>

    <!-- Layout for the toggle and dialog components -->
    <v-layout column justify-center align-center fill-height>
      <div class="Body-1-Black-High-Emphasis-Center Loyalty-Description-Messages">
        Allow Frictionless?
      </div>

      <!-- Toggle for enabling/disabling Frictionless feature -->
      <toggle v-model="frictionless_supportedProxy" :disabled="isSectionDisabled" />

      <!-- Dialog component to manage Frictionless configurations -->
      <frictionless-dialog
        :dialog="showDialog"
        @submit="submitFrictionlessStore"
        @close="frictionlessDialogClose"
      />
    </v-layout>

    <!-- Dropdown for selecting third-party partner only if Frictionless is supported and not site configuration -->
    <div v-if="frictionless_supportedProxy && !isSiteConfig" style="margin: 0px 20px 50px">
      <v-flex xs12>
        <v-select
          v-model="frictionless_third_party_partnerProxy"
          label="Frictionless Third-Party Partner"
          :rules="requiredRules"
          :items="getPartners()"
        ></v-select>
      </v-flex>
    </div>
  </v-card>
</template>

<script>
import rules from '@/rules'; // Import validation rules
import { SITE } from '@/constants'; // Import constants to use in the component
import frictionlessDialog from './frictionlessDialog'; // Import the Frictionless dialog component

export default {
  components: {
    frictionlessDialog, // Declare the imported dialog component
  },
  props: {
    withDialog: { type: Boolean, default: true },
    frictionlessProps: { type: Object, required: true, default: () => ({}) },
    isSectionDisabled: { type: Boolean, default: false },
    configLevel: { type: String },
  },
  data() {
    return {
      showDialog: false, // Controls visibility of the dialog
      SITE, // Constant for site level configuration
      requiredRules: [rules.required('This field is required')], // Validation rules for forms
    };
  },
  computed: {
    // Proxy for reactive updates to Frictionless properties
    frictionlessState: {
      get() {
        return this.frictionlessProps;
      },
      set(value) {
        this.$emit('update:frictionlessProps', value);
      },
    },
    // Proxy getters and setters for third-party partners
    frictionless_third_party_partnerProxy: {
      get() {
        return this.frictionlessProps.frictionless_third_party_partner;
      },
      set(value) {
        this.changeThirdPartyPartner(value);
      },
    },
    // Proxy for toggling Frictionless support
    frictionless_supportedProxy: {
      get() {
        return this.frictionlessProps.frictionless_supported;
      },
      set(value) {
        this.toggleFrictionless(value);
      },
    },
    // Determines if the current configuration is at the site level
    isSiteConfig() {
      return this.configLevel === this.SITE;
    },
  },
  methods: {
    // Method to retrieve partners for the dropdown
    getPartners() {
      return [{ text: 'Amazon JWO', value: 'AWS' }]; // Example partner list
    },
    // Handles changes in the third-party partner selection
    async changeThirdPartyPartner(selectedOptions) {
      const updatedProps = {
        ...this.frictionlessState,
        frictionless_third_party_partner: selectedOptions,
      };
      this.$emit('update:frictionlessProps', updatedProps);
      this.$emit('changeThirdPartyPartner', selectedOptions);

      if (selectedOptions === 'AWS') {
        this.changeThirdPartyTechnology(undefined);
      }
    },
    // Handles changes in the third-party tech selection
    async changeThirdPartyTechnology(selectedOptions) {
      this.$emit('changeThirdPartyTechnology', selectedOptions);
    },
    // Submits the selected Frictionless store settings
    async submitFrictionlessStore(selectedStore) {
      try {
        this.showDialog = false;
        const update = await new Promise((resolve, reject) => {
          this.$emit(
            'submitFrictionlessStore',
            {
              frictionless_supported: this.frictionless_supportedProxy,
              frictionless_store_uuid: selectedStore.frictionless_store_uuid,
            },
            resolve,
            reject,
          );
        });
        this.frictionlessState = update;
      } catch (error) {
        console.error('Failed to submit Frictionless store:', error);
        this.showDialog = true; // Re-open dialog on failure
      }
    },
    // Toggles the Frictionless support and handles confirmation
    async toggleFrictionless(value) {
      try {
        this.showDialog = false;
        const input = await this.$confirm({
          title: 'Warning',
          message: `Are you sure you want to ${
            value ? 'enable' : 'remove'
          } the Frictionless option?`,
          buttonTrueText: 'OK',
          buttonFalseText: 'CANCEL',
        });

        if (input) {
          const updatedProps = {
            ...this.frictionlessState,
            frictionless_supported: value,
            frictionless_third_party_partner: null,
            frictionless_store_uuid: null,
          };
          this.$emit('update:frictionlessProps', updatedProps);
          await new Promise((resolve) =>
            this.$emit('changeFrictionlessAllowed', updatedProps, resolve),
          );
        } else {
          this.frictionlessState = { ...this.frictionlessState, frictionless_supported: !value };
        }
      } catch (error) {
        console.error('Failed to toggle Frictionless:', error);
      }
    },
    // Handles closing of the dialog and resets the toggle state
    frictionlessDialogClose() {
      this.showDialog = false;
      const updatedProps = {
        ...this.frictionlessState,
        frictionless_supported: !this.frictionlessState.frictionless_supported,
      };
      this.$emit('update:frictionlessProps', updatedProps);
    },
  },
};
</script>
