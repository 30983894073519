import { MEALPLAN_TYPES } from '@/constants';

export default {
  isTypeItc(string) {
    return /^[ITC]{3}/i.test(string);
  },
  isTypeHeartland(mealplanType) {
    return mealplanType === MEALPLAN_TYPES.HEARTLAND;
  },
  isTypeBlackboard(mealplanType) {
    return (
      mealplanType === MEALPLAN_TYPES.BLACKBOARD || mealplanType === MEALPLAN_TYPES.BLACKBOARD_SAAS
    );
  },
  isTypeCbord(mealplanType) {
    return mealplanType === MEALPLAN_TYPES.CBORD || mealplanType === MEALPLAN_TYPES.CBORD_DIRECT;
  },
  isCbordConfig(publicConfig) {
    return (
      publicConfig.mealplan &&
      publicConfig.mealplan[0] &&
      publicConfig.mealplan[0].type &&
      (publicConfig.mealplan[0].type === MEALPLAN_TYPES.CBORD ||
        publicConfig.mealplan[0].type === MEALPLAN_TYPES.CBORD_DIRECT)
    );
  },
  getMealplanProviderName(type) {
    if (this.isTypeItc(type)) {
      return type.substr(0, 3).toLowerCase();
    }
    if (this.isTypeBlackboard(type)) {
      return MEALPLAN_TYPES.BLACKBOARD.toLowerCase();
    }
    if (this.isTypeCbord(type)) {
      return MEALPLAN_TYPES.CBORD.toLowerCase();
    }
    return type.toLowerCase();
  },
  mealPlanConfigExists(config) {
    return config && config.mealplan && config.mealplan.length;
  },
};
