import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    [
      _c(VCardTitle, { attrs: { "primary-title": "" } }, [
        _c("div", { staticClass: "H5-Primary-Left" }, [_vm._v("Payment ID")])
      ]),
      _c(
        "div",
        { staticClass: "v-card-content" },
        [
          _c(
            VLayout,
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                VFlex,
                { attrs: { xs8: "" } },
                [
                  _c(VSelect, {
                    attrs: {
                      items: _vm.types,
                      label: "Payment Type",
                      value: _vm.selectedPayment,
                      id: "payment_payment_type_select",
                      placeholder: "Select Payment Type",
                      rules: _vm.requiredField,
                      disabled: _vm.isSectionDisabled
                    },
                    on: { input: _vm.selectPayment }
                  })
                ],
                1
              ),
              _c(
                VFlex,
                { attrs: { x4: "" } },
                [
                  _vm.hasPermissions([_vm.customPermissions.viewPaymentInfo]) &&
                  !_vm.isEditing
                    ? _c(VCheckbox, {
                        attrs: {
                          "hide-details": "",
                          "on-icon": "mdi-eye-off",
                          "off-icon": "mdi-eye",
                          "false-value": false,
                          "true-value": true,
                          disabled: _vm.isSectionDisabled,
                          title: "Show payment IDs"
                        },
                        nativeOn: {
                          click: function($event) {
                            $event.stopPropagation()
                          }
                        },
                        model: {
                          value: _vm.showPaymentIds,
                          callback: function($$v) {
                            _vm.showPaymentIds = $$v
                          },
                          expression: "showPaymentIds"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            VForm,
            { ref: "form" },
            [
              _vm.isPaymentConfigValidated && _vm.isPaymentConfigValid
                ? _c("div", { staticStyle: { color: "#43a047" } }, [
                    _vm._v(
                      "\n        Success validating payment config!\n      "
                    )
                  ])
                : _vm._e(),
              _vm.isPaymentConfigValidated && !_vm.isPaymentConfigValid
                ? _c("div", { staticStyle: { color: "#FF5252" } }, [
                    _vm._v("\n        Error validating payment config!\n      ")
                  ])
                : _vm._e(),
              _vm.payment.freedompay
                ? _c(
                    VLayout,
                    { attrs: { "mb-2": "", row: "", wrap: "" } },
                    [
                      _c(
                        VFlex,
                        { attrs: { xs8: "" } },
                        [
                          _vm.hasPermissions([
                            _vm.customPermissions.viewPaymentInfo
                          ]) || _vm.isEditing
                            ? _c(VTextField, {
                                attrs: {
                                  value: _vm.freedompay_store_id,
                                  label: "Freedom Pay Store ID",
                                  rules: _vm.requiredField,
                                  disabled:
                                    _vm.isSectionDisabled || !_vm.isEditing,
                                  id: "payment_freedom_pay_store_id_field",
                                  type: _vm.paymentInputsType
                                },
                                on: {
                                  input: function($event) {
                                    _vm.freedompay_store_id = _vm.safeTrim(
                                      $event
                                    )
                                    _vm.fieldUpdated()
                                  }
                                }
                              })
                            : _vm._e(),
                          !_vm.hasPermissions([
                            _vm.customPermissions.viewPaymentInfo
                          ]) && !_vm.isEditing
                            ? _c(VTextField, {
                                attrs: {
                                  value: "*********",
                                  label: "Freedom Pay Store ID",
                                  disabled: true,
                                  id:
                                    "payment_freedom_pay_store_id_field dummy_field",
                                  type: _vm.paymentInputsType
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        VFlex,
                        { attrs: { xs8: "" } },
                        [
                          _vm.hasPermissions([
                            _vm.customPermissions.viewPaymentInfo
                          ]) || _vm.isEditing
                            ? _c(VTextField, {
                                staticStyle: { "margin-bottom": "1em" },
                                attrs: {
                                  value: _vm.freedompay_terminal_id,
                                  label: "Freedom Pay Terminal ID",
                                  rules: _vm.requiredField,
                                  id: "payment_freedom_pay_terminal_id_field",
                                  disabled:
                                    _vm.isSectionDisabled || !_vm.isEditing,
                                  type: _vm.paymentInputsType
                                },
                                on: {
                                  input: function($event) {
                                    _vm.freedompay_terminal_id = _vm.safeTrim(
                                      $event
                                    )
                                    _vm.fieldUpdated()
                                  }
                                }
                              })
                            : _vm._e(),
                          !_vm.hasPermissions([
                            _vm.customPermissions.viewPaymentInfo
                          ]) && !_vm.isEditing
                            ? _c(VTextField, {
                                attrs: {
                                  value: "*********",
                                  label: "Freedom Pay Terminal ID",
                                  disabled: true,
                                  id:
                                    "payment_freedom_pay_terminal_id_field dummy_field",
                                  type: _vm.paymentInputsType
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(VFlex, { attrs: { xs4: "" } })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.selectedPayment && !_vm.isBrandConfig
            ? _c(
                VFlex,
                { attrs: { "mt-1": "", xs12: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "Subtitle-1-Black-Medium-Emphasis-Left" },
                    [_vm._v("Refunds Accepted?")]
                  ),
                  _c("toggle", {
                    attrs: {
                      value: _vm.refund,
                      position: "start",
                      disabled: _vm.isSectionDisabled
                    },
                    on: {
                      input: function($event) {
                        return _vm.$emit("update:refund", $event)
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.isAdmin
            ? _c(
                VFooter,
                {
                  staticStyle: { background: "white !important" },
                  attrs: { absolute: "", height: "60" }
                },
                [
                  _vm.payment.freedompay
                    ? _c(
                        VLayout,
                        { attrs: { row: "" } },
                        [
                          _c(VFlex, { attrs: { xs11: "" } }),
                          _c(
                            VFlex,
                            { attrs: { shrink: "" } },
                            [
                              !_vm.isEditing
                                ? _c(
                                    VBtn,
                                    {
                                      attrs: { flat: "", color: "blue" },
                                      on: { click: _vm.editPaymentInfo }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        {
                                          staticStyle: { "margin-right": "6px" }
                                        },
                                        [_vm._v("mdi-pencil")]
                                      ),
                                      _vm._v("EDIT PAYMENT INFO\n          ")
                                    ],
                                    1
                                  )
                                : _c(
                                    VCardActions,
                                    {
                                      staticStyle: { "margin-right": "15px" },
                                      attrs: { row: "" }
                                    },
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          attrs: { color: "gray", flat: "" },
                                          on: { click: _vm.validatePayment }
                                        },
                                        [
                                          _c(
                                            VIcon,
                                            {
                                              staticStyle: {
                                                "margin-left": "6px"
                                              }
                                            },
                                            [_vm._v("mdi-check")]
                                          ),
                                          _vm._v("validate\n            ")
                                        ],
                                        1
                                      ),
                                      _c(
                                        VBtn,
                                        {
                                          attrs: { color: "primary", flat: "" },
                                          on: { click: _vm.cancelEdit }
                                        },
                                        [_vm._v("cancel")]
                                      ),
                                      _c(
                                        VBtn,
                                        {
                                          attrs: {
                                            disabled: !_vm.isPaymentConfigValid,
                                            color: "primary"
                                          },
                                          on: { click: _vm.saveChanges }
                                        },
                                        [_vm._v("save changes")]
                                      )
                                    ],
                                    1
                                  )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }