<template>
  <div></div>
</template>

<script>
export default {
  props: ['options'],
  data: () => ({
    observer: null,
  }),
  mounted() {
    // Pass options prop or empty object (https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#creating_an_intersection_observer)
    const options = this.options || {};

    this.observer = new IntersectionObserver(([entry]) => {
      if (entry) {
        this.$emit('intersect', entry.isIntersecting);
      }
    }, options);

    this.observer.observe(this.$el);
  },
  destroyed() {
    this.observer.disconnect();
  },
};
</script>
