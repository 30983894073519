import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    [
      _c(
        VContainer,
        { attrs: { "grid-list-xl": "" } },
        [
          _c(
            VLayout,
            { attrs: { row: "", wrap: "", "align-baseline": "" } },
            [
              _c(VFlex, { attrs: { xs3: "" } }, [
                _c("div", { staticClass: "H5-Primary-Left" }, [
                  _vm._v("Meal Plan")
                ])
              ]),
              _c(VSpacer),
              _vm.mealplanObject && _vm.isMealplanConfigVisible
                ? _c(
                    VBtn,
                    {
                      attrs: {
                        disabled: _vm.isSectionDisabled,
                        flat: "",
                        color: "secondary"
                      },
                      on: {
                        click: function($event) {
                          _vm.showRemoveDialog = true
                        }
                      }
                    },
                    [
                      _c(
                        VIcon,
                        { staticClass: "mr-1", attrs: { left: "" } },
                        [_vm._v("mdi-delete-outline")]
                      ),
                      _vm._v("remove\n      ")
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.isBrandMealplanMissing && !_vm.showBrandMealplanSetup
            ? _c(
                VLayout,
                {
                  attrs: {
                    column: "",
                    "justify-center": "",
                    "align-center": "",
                    "fill-height": ""
                  }
                },
                [
                  !_vm.siteMealplanObject
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "Body-1-Black-High-Emphasis-Center Loyalty-Description-Messages"
                        },
                        [
                          _vm._v(
                            "\n        Meal Plan is not configured for this brand at site level\n      "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.siteMealplanObject
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "Subtitle-1-Black-Medium-Emphasis-Center Loyalty-Description-Messages"
                        },
                        [
                          _vm._v(
                            "\n        Would you like to activate meal plan?\n      "
                          )
                        ]
                      )
                    : _vm._e(),
                  _c("toggle", {
                    model: {
                      value: _vm.showBrandMealplanSetup,
                      callback: function($$v) {
                        _vm.showBrandMealplanSetup = $$v
                      },
                      expression: "showBrandMealplanSetup"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.isMealplanConfigVisible
            ? [
                _c(
                  VLayout,
                  { attrs: { row: "", wrap: "" } },
                  [
                    _c(
                      VFlex,
                      { attrs: { xs5: "" } },
                      [
                        _c(VSelect, {
                          attrs: {
                            items: _vm.types,
                            label: "Plan Type",
                            placeholder: "Choose a Plan",
                            disabled: _vm.isSectionDisabled || _vm.isBrandConfig
                          },
                          model: {
                            value: _vm.activeMealplanType,
                            callback: function($$v) {
                              _vm.activeMealplanType = $$v
                            },
                            expression: "activeMealplanType"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.mealplanObject
                  ? [
                      _c(
                        VLayout,
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            VFlex,
                            { attrs: { xs5: "" } },
                            [
                              _c(VTextField, {
                                attrs: {
                                  value: _vm.getPlanName,
                                  rules: _vm.planNameRules,
                                  disabled: _vm.isPlanNameDisabled,
                                  label: "Plan Name"
                                },
                                on: { input: _vm.setPlanName }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.isSiteConfig && _vm.isCbord
                        ? [
                            _c("cbord", {
                              attrs: {
                                mealplan: _vm.mealplanObject,
                                isSectionDisabled: _vm.isSectionDisabled,
                                id: "cbord-detail"
                              },
                              on: {
                                "update:mealplan": function($event) {
                                  _vm.mealplanObject = $event
                                }
                              }
                            })
                          ]
                        : _vm._e(),
                      _vm.isAtrium
                        ? [
                            _vm.mealplanObject.private &&
                            _vm.mealplanObject.private.keystore
                              ? _c(
                                  VLayout,
                                  { attrs: { row: "", wrap: "" } },
                                  [
                                    _c(
                                      VFlex,
                                      { attrs: { xs12: "" } },
                                      [
                                        _c(VTextField, {
                                          attrs: {
                                            disabled: _vm.isSectionDisabled,
                                            label: "Key",
                                            rules: _vm.requiredField
                                          },
                                          model: {
                                            value:
                                              _vm.mealplanObject.private
                                                .keystore.key,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.mealplanObject.private
                                                  .keystore,
                                                "key",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "mealplanObject.private.keystore.key"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      VFlex,
                                      { attrs: { xs12: "" } },
                                      [
                                        _c(VTextField, {
                                          attrs: {
                                            disabled: _vm.isSectionDisabled,
                                            label: "API URL",
                                            rules: _vm.requiredField
                                          },
                                          model: {
                                            value:
                                              _vm.mealplanObject.private
                                                .keystore.API_URL,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.mealplanObject.private
                                                  .keystore,
                                                "API_URL",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "mealplanObject.private.keystore.API_URL"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        : _vm._e(),
                      _vm.isBlackBoard && _vm.isSiteConfig
                        ? [
                            _vm.mealplanObject.private &&
                            _vm.mealplanObject.private.keystore
                              ? _c(
                                  VLayout,
                                  { attrs: { row: "", wrap: "" } },
                                  [
                                    _c(
                                      VFlex,
                                      { attrs: { xs12: "" } },
                                      [
                                        _c(VTextField, {
                                          attrs: {
                                            disabled: _vm.isSectionDisabled,
                                            label: "Host",
                                            rules: _vm.requiredField.concat(
                                              _vm.isIPAddress
                                            )
                                          },
                                          model: {
                                            value:
                                              _vm.mealplanObject.private
                                                .keystore.host,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.mealplanObject.private
                                                  .keystore,
                                                "host",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "mealplanObject.private.keystore.host"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      VFlex,
                                      { attrs: { xs12: "" } },
                                      [
                                        _c(VTextField, {
                                          attrs: {
                                            disabled: _vm.isSectionDisabled,
                                            label: "Port",
                                            type: "number",
                                            rules: _vm.requiredField
                                          },
                                          model: {
                                            value:
                                              _vm.mealplanObject.private
                                                .keystore.port,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.mealplanObject.private
                                                  .keystore,
                                                "port",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "mealplanObject.private.keystore.port"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      VFlex,
                                      { attrs: { xs12: "" } },
                                      [
                                        _c(VTextField, {
                                          attrs: {
                                            disabled: _vm.isSectionDisabled,
                                            label: "AES key",
                                            rules: _vm.requiredField
                                          },
                                          model: {
                                            value:
                                              _vm.mealplanObject.private
                                                .keystore.aes_key,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.mealplanObject.private
                                                  .keystore,
                                                "aes_key",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "mealplanObject.private.keystore.aes_key"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      VFlex,
                                      { attrs: { xs12: "" } },
                                      [
                                        _c(VSelect, {
                                          attrs: {
                                            disabled: _vm.isSectionDisabled,
                                            label: "Vendor number",
                                            items: [118, 3600],
                                            rules: _vm.requiredField
                                          },
                                          model: {
                                            value:
                                              _vm.mealplanObject.private
                                                .keystore.vendor_number,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.mealplanObject.private
                                                  .keystore,
                                                "vendor_number",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "mealplanObject.private.keystore.vendor_number"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        : _vm._e(),
                      _vm.isHeartland
                        ? [
                            _vm.mealplanObject.private &&
                            _vm.mealplanObject.private.keystore
                              ? _c(
                                  VLayout,
                                  { attrs: { row: "", wrap: "" } },
                                  [
                                    _vm.isSiteConfig
                                      ? [
                                          _c(
                                            VFlex,
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(VTextField, {
                                                attrs: {
                                                  disabled:
                                                    _vm.isSectionDisabled,
                                                  rules: _vm.requiredField.concat(
                                                    _vm.isAlphaNumeric
                                                  ),
                                                  label: "Operator ID"
                                                },
                                                model: {
                                                  value:
                                                    _vm.mealplanObject.private
                                                      .keystore.operator_id,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.mealplanObject.private
                                                        .keystore,
                                                      "operator_id",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "mealplanObject.private.keystore.operator_id"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            VFlex,
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(VTextField, {
                                                attrs: {
                                                  disabled:
                                                    _vm.isSectionDisabled,
                                                  label: "Operator Password",
                                                  rules: _vm.requiredField.concat(
                                                    _vm.isAlphaNumericAndSpecialChars
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.mealplanObject.private
                                                      .keystore
                                                      .operator_password,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.mealplanObject.private
                                                        .keystore,
                                                      "operator_password",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "mealplanObject.private.keystore.operator_password"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            VFlex,
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(VTextField, {
                                                attrs: {
                                                  disabled:
                                                    _vm.isSectionDisabled,
                                                  label: "TouchNet API",
                                                  rules: _vm.requiredField.concat(
                                                    _vm.isUrl
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.mealplanObject.private
                                                      .keystore.api_url,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.mealplanObject.private
                                                        .keystore,
                                                      "api_url",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "mealplanObject.private.keystore.api_url"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _c(
                              VLayout,
                              [
                                _c(
                                  VFlex,
                                  { attrs: { xs12: "" } },
                                  [
                                    _c(VSelect, {
                                      attrs: {
                                        label: "Tax Exempt",
                                        placeholder:
                                          "Please select tax exemption",
                                        value: _vm.getHeartlandTenderTaxExempt,
                                        rules: _vm.taxRules,
                                        items: _vm.taxExemptionOptions,
                                        disabled: _vm.isSectionDisabled
                                      },
                                      on: {
                                        change: function(v) {
                                          return _vm.setHeartlandTender(v)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      !_vm.isCbord && !_vm.isBlackBoard && !_vm.isAtrium
                        ? _c(
                            VLayout,
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                VFlex,
                                { attrs: { xs12: "" } },
                                [
                                  _c(VTextField, {
                                    attrs: {
                                      label: "Plan ID",
                                      value: _vm.mealplanObject.id,
                                      rules: _vm.planIDRules,
                                      disabled: _vm.isSectionDisabled
                                    },
                                    on: {
                                      input: function(id) {
                                        return _vm.setMealplanId(id)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        VLayout,
                        { attrs: { row: "", wrap: "" } },
                        [
                          !_vm.isItc
                            ? _c(
                                VFlex,
                                { attrs: { xs12: "" } },
                                [
                                  _c("label-lang", {
                                    attrs: {
                                      disabled: _vm.isSectionDisabled,
                                      label: "Additional Instructions"
                                    },
                                    model: {
                                      value: _vm.mealplanNote,
                                      callback: function($$v) {
                                        _vm.mealplanNote = $$v
                                      },
                                      expression: "mealplanNote"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isSiteConfig && _vm.isItc
                            ? _c(
                                VLayout,
                                [
                                  _c(
                                    VFlex,
                                    {
                                      staticStyle: {
                                        padding: "0 3.25% 0 3.25%"
                                      },
                                      attrs: { xs12: "" }
                                    },
                                    [
                                      _c(VTextField, {
                                        attrs: {
                                          rules: _vm.requiredField,
                                          disabled: _vm.isSectionDisabled,
                                          label: "Certificate"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.$refs.certificate.click()
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.mealplanObject.private.keystore
                                              .certificate.name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.mealplanObject.private
                                                .keystore.certificate,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "mealplanObject.private.keystore.certificate.name"
                                        }
                                      }),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: false,
                                            expression: "false"
                                          }
                                        ],
                                        ref: "certificate",
                                        attrs: { type: "file", accept: ".pfx" },
                                        on: {
                                          change: _vm.handleItcCertificateUpload
                                        }
                                      }),
                                      _c(VTextField, {
                                        attrs: {
                                          rules: _vm.requiredField,
                                          disabled: _vm.isSectionDisabled,
                                          label: "Certificate Password"
                                        },
                                        model: {
                                          value:
                                            _vm.mealplanObject.private.keystore
                                              .certificate.password,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.mealplanObject.private
                                                .keystore.certificate,
                                              "password",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "mealplanObject.private.keystore.certificate.password"
                                        }
                                      }),
                                      _c(VTextField, {
                                        attrs: {
                                          rules: _vm.requiredField.concat(
                                            _vm.isUrl
                                          ),
                                          disabled: _vm.isSectionDisabled,
                                          label: "Connection URL"
                                        },
                                        model: {
                                          value:
                                            _vm.mealplanObject.private.keystore
                                              .endpoint,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.mealplanObject.private
                                                .keystore,
                                              "endpoint",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "mealplanObject.private.keystore.endpoint"
                                        }
                                      }),
                                      _c(VTextField, {
                                        attrs: {
                                          rules: _vm.requiredField.concat(
                                            _vm.isAlphaNumeric
                                          ),
                                          disabled: _vm.isSectionDisabled,
                                          label: "Vendor ID"
                                        },
                                        model: {
                                          value:
                                            _vm.mealplanObject.private.keystore
                                              .vendor_id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.mealplanObject.private
                                                .keystore,
                                              "vendor_id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "mealplanObject.private.keystore.vendor_id"
                                        }
                                      }),
                                      _c(VTextField, {
                                        attrs: {
                                          rules: _vm.requiredField.concat(
                                            _vm.isUrl
                                          ),
                                          disabled: _vm.isSectionDisabled,
                                          label: "Webview URL"
                                        },
                                        model: {
                                          value:
                                            _vm.mealplanObject.private.keystore
                                              .webview,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.mealplanObject.private
                                                .keystore,
                                              "webview",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "mealplanObject.private.keystore.webview"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            VFlex,
                            { attrs: { xs12: "" } },
                            [
                              !_vm.allowMultipleTerminalIds
                                ? _c(VTextField, {
                                    attrs: {
                                      label: "Meal Plan Terminal ID",
                                      disabled: _vm.isSectionDisabled,
                                      rules: _vm.terminalIdRules
                                    },
                                    model: {
                                      value: _vm.terminalId,
                                      callback: function($$v) {
                                        _vm.terminalId = $$v
                                      },
                                      expression: "terminalId"
                                    }
                                  })
                                : _vm._e(),
                              _vm.allowMultipleTerminalIds
                                ? [
                                    _vm.isSiteConfig
                                      ? _c(VCombobox, {
                                          attrs: {
                                            label: "Meal Plan Terminal ID",
                                            multiple: "",
                                            "small-chips": "",
                                            rules: _vm.terminalIdRules
                                          },
                                          model: {
                                            value: _vm.terminalId,
                                            callback: function($$v) {
                                              _vm.terminalId = $$v
                                            },
                                            expression: "terminalId"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.isBrandConfig
                                      ? _c(VSelect, {
                                          attrs: {
                                            items: _vm.siteTerminalIds,
                                            label: "Meal Plan Terminal ID",
                                            placeholder: "Select Terminal IDs",
                                            disabled: _vm.isSectionDisabled
                                          },
                                          model: {
                                            value: _vm.terminalId,
                                            callback: function($$v) {
                                              _vm.terminalId = $$v
                                            },
                                            expression: "terminalId"
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      !_vm.isHeartland
                        ? [
                            _c(
                              VLayout,
                              { attrs: { row: "", wrap: "" } },
                              _vm._l(_vm.tenders, function(tender, index) {
                                return _c(
                                  VFlex,
                                  {
                                    key: index,
                                    staticClass: "display-flex align-center"
                                  },
                                  [
                                    _c(
                                      VFlex,
                                      { attrs: { xs1: "" } },
                                      [
                                        _vm.shouldAllowRemoveTender
                                          ? _c(
                                              VIcon,
                                              {
                                                staticStyle: {
                                                  cursor: "pointer"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.removeTender(
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("mdi-close")]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _c(
                                      VFlex,
                                      { attrs: { xs5: "" } },
                                      [
                                        _c(VSelect, {
                                          attrs: {
                                            value: tender.type,
                                            items: _vm.tenderTypesProxy,
                                            rules: _vm.tenderIdAndTypeRules(
                                              tender
                                            ),
                                            disabled: _vm.isSectionDisabled,
                                            label: "Tender Type",
                                            placeholder:
                                              "Please select Tender Type"
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.setTenderType(
                                                tender,
                                                $event
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    !_vm.isItc
                                      ? _c(
                                          VFlex,
                                          { attrs: { xs6: "" } },
                                          [
                                            _c(VTextField, {
                                              attrs: {
                                                rules: _vm.tenderNameRules,
                                                disabled: _vm.isSectionDisabled,
                                                label: "Name of Tender",
                                                placeholder:
                                                  "Please insert name of tender"
                                              },
                                              model: {
                                                value: tender.name,
                                                callback: function($$v) {
                                                  _vm.$set(tender, "name", $$v)
                                                },
                                                expression: "tender.name"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.shouldDisplayTenderId(tender.type)
                                      ? _c(
                                          VFlex,
                                          {
                                            attrs: {
                                              xs6: "",
                                              row: "",
                                              wrap: ""
                                            }
                                          },
                                          [
                                            _c(VTextField, {
                                              attrs: {
                                                label: "Tender ID",
                                                value: _vm.getTenderId(tender),
                                                rules: _vm
                                                  .tenderIdAndTypeRules(tender)
                                                  .concat(
                                                    _vm.tenderIdRules(tender)
                                                  ),
                                                disabled: _vm.isSectionDisabled
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.updateTenderId(
                                                    tender,
                                                    index,
                                                    $event
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    tender.type !==
                                    _vm.TENDER_TYPES.MEAL_EXCHANGE
                                      ? _c(
                                          VFlex,
                                          { attrs: { xs6: "" } },
                                          [
                                            _c(VSelect, {
                                              attrs: {
                                                label: "Tax Exempt",
                                                placeholder:
                                                  "Please select tax exemption",
                                                value: _vm.getTenderTaxExempt(
                                                  tender,
                                                  index
                                                ),
                                                rules: _vm.taxRules,
                                                items: _vm.taxExemptionOptions,
                                                disabled: _vm.isSectionDisabled
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.setTenderTaxExempt(
                                                    tender,
                                                    index,
                                                    $event
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.shouldDisplayTenderSetup(tender.type)
                                      ? _c(
                                          VFlex,
                                          { attrs: { shrink: "" } },
                                          [
                                            _c(
                                              VBtn,
                                              {
                                                attrs: {
                                                  flat: "",
                                                  color: "primary",
                                                  disabled:
                                                    _vm.isSectionDisabled
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.setupTender(
                                                      tender,
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("Tender Setup")]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                            _c(
                              VFlex,
                              [
                                _c(
                                  VBtn,
                                  {
                                    attrs: {
                                      flat: "",
                                      color: "primary",
                                      disabled: _vm.isSectionDisabled
                                    },
                                    on: { click: _vm.addTender }
                                  },
                                  [
                                    _c(VIcon, [_vm._v("mdi-plus")]),
                                    _vm._v(
                                      "add another tender type\n            "
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm.isBrandConfig
                        ? [
                            _vm.isCbord
                              ? _c(
                                  VLayout,
                                  { attrs: { row: "" } },
                                  [
                                    _c(
                                      VFlex,
                                      [
                                        _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "Subtitle-1-Black-Medium-Emphasis-Left"
                                          },
                                          [
                                            _vm._v(
                                              "\n                Meal Equivalency Station ($ Value)?\n              "
                                            )
                                          ]
                                        ),
                                        _c("toggle", {
                                          staticClass: "ml-0",
                                          attrs: {
                                            position: "start",
                                            stylingClass: "my-2",
                                            disabled:
                                              _vm.mealplanObject.isBrandMX ||
                                              !_vm.hasMEQTenders
                                          },
                                          model: {
                                            value:
                                              _vm.mealplanObject.isBrandMEQ,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.mealplanObject,
                                                "isBrandMEQ",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "mealplanObject.isBrandMEQ"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isCbord || _vm.isBlackBoard
                              ? _c(
                                  VLayout,
                                  { attrs: { row: "" } },
                                  [
                                    _c(
                                      VFlex,
                                      [
                                        _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "Subtitle-1-Black-Medium-Emphasis-Left"
                                          },
                                          [
                                            _vm._v(
                                              "Meals Station (“Meal” Value)?"
                                            )
                                          ]
                                        ),
                                        _c("toggle", {
                                          staticClass: "ml-0",
                                          attrs: {
                                            position: "start",
                                            stylingClass: "my-2",
                                            disabled:
                                              _vm.mealplanObject.isBrandMEQ ||
                                              !_vm.hasMXTenders
                                          },
                                          model: {
                                            value: _vm.mealplanObject.isBrandMX,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.mealplanObject,
                                                "isBrandMX",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "mealplanObject.isBrandMX"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        : _vm._e(),
                      _vm.isDomainManagementVisible
                        ? [
                            !_vm.isSectionDisabled
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "Body-1-Primary-Left Domain-Emails-Link",
                                    on: { click: _vm.openDomainDialog }
                                  },
                                  [
                                    _vm._v(
                                      "\n            Manage Meal Plan Domains\n          "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "Caption-Black-Medium-Emphasis-Left"
                              },
                              [
                                _vm._v(
                                  "\n            Mealplan Access:\n            "
                                ),
                                !_vm.mealplanObject.valid_email_domains ||
                                !_vm.mealplanObject.valid_email_domains.length
                                  ? [_vm._v("All Domains")]
                                  : [
                                      _vm._v(
                                        _vm._s(
                                          _vm.mealplanObject.valid_email_domains
                                            .length
                                        ) + " Domain(s)"
                                      )
                                    ]
                              ],
                              2
                            )
                          ]
                        : _vm._e()
                    ]
                  : _vm._e(),
                _c(
                  VDialog,
                  {
                    attrs: { width: "800" },
                    model: {
                      value: _vm.dialog,
                      callback: function($$v) {
                        _vm.dialog = $$v
                      },
                      expression: "dialog"
                    }
                  },
                  [
                    _c(
                      VCard,
                      [
                        _c(
                          VCardTitle,
                          { staticClass: "H4-Secondary-Center" },
                          [_vm._v("Manage Meal Plan Domains")]
                        ),
                        _c(
                          VForm,
                          { ref: "form", staticClass: "pa-4" },
                          [
                            _c(
                              VLayout,
                              [
                                _c(
                                  VFlex,
                                  { attrs: { grow: "" } },
                                  [
                                    _c(VTextField, {
                                      staticClass: "mb-2",
                                      attrs: {
                                        label: "Email Domain",
                                        rules: _vm.domainRules
                                      },
                                      on: {
                                        keydown: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.addDomain($event)
                                        }
                                      },
                                      model: {
                                        value: _vm.domainInput,
                                        callback: function($$v) {
                                          _vm.domainInput = $$v
                                        },
                                        expression: "domainInput"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  VFlex,
                                  { attrs: { shrink: "", "py-2": "" } },
                                  [
                                    _c(
                                      VLayout,
                                      { attrs: { "justify-end": "" } },
                                      [
                                        _c(
                                          VBtn,
                                          {
                                            attrs: {
                                              dark: "",
                                              color: "#0c62c6"
                                            },
                                            on: { click: _vm.addDomain }
                                          },
                                          [_vm._v("ADD")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("div", { staticClass: "Domain-Dialog-List" }, [
                              _vm.tempDomains && _vm.tempDomains.length > 0
                                ? _c(
                                    "div",
                                    _vm._l(_vm.tempDomains, function(d, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "Domain-Cell"
                                        },
                                        [
                                          _c(
                                            VLayout,
                                            {
                                              staticClass: "Body-1 pl-2",
                                              attrs: {
                                                row: "",
                                                wrap: "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c(VFlex, [_vm._v(_vm._s(d))]),
                                              _c(
                                                VFlex,
                                                [
                                                  _c(
                                                    VLayout,
                                                    {
                                                      attrs: {
                                                        "justify-end": ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        VBtn,
                                                        {
                                                          attrs: {
                                                            flat: "",
                                                            color: "grey"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.tempDomains.splice(
                                                                index,
                                                                1
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            VIcon,
                                                            {
                                                              staticClass:
                                                                "mr-1",
                                                              attrs: {
                                                                left: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-delete-outline"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            "REMOVE\n                        "
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "mt-4" },
                                    [
                                      _c(
                                        VLayout,
                                        {
                                          attrs: {
                                            column: "",
                                            "justify-center": "",
                                            "align-center": "",
                                            "fill-height": ""
                                          }
                                        },
                                        [
                                          _c(VImg, {
                                            attrs: {
                                              src: require("@/assets/loyalty-email.svg"),
                                              height: "158px",
                                              width: "179px"
                                            }
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "Body-1-Black-High-Emphasis-Center Loyalty-Description-Messages"
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Enter Email Domains to allow on certain users\n                  "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "Subtitle-1-Black-Medium-Emphasis-Center Loyalty-Description-Messages"
                                            },
                                            [
                                              _vm._v(
                                                "\n                    to register Meal Plan\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                            ])
                          ],
                          1
                        ),
                        _c(VDivider),
                        _c(
                          VCardActions,
                          [
                            _c(VSpacer),
                            _c(
                              VBtn,
                              {
                                attrs: { color: "primary", flat: "" },
                                on: {
                                  click: function($event) {
                                    _vm.dialog = false
                                  }
                                }
                              },
                              [_vm._v("cancel")]
                            ),
                            _c(
                              VBtn,
                              {
                                attrs: { color: "primary", flat: "" },
                                on: { click: _vm.saveDomains }
                              },
                              [_vm._v("save")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  VDialog,
                  {
                    attrs: { persistent: "", width: "850" },
                    model: {
                      value: _vm.meal_equivalent_dialog,
                      callback: function($$v) {
                        _vm.meal_equivalent_dialog = $$v
                      },
                      expression: "meal_equivalent_dialog"
                    }
                  },
                  [
                    !!_vm.meal_equivalent_tender_edit
                      ? _c(
                          VCard,
                          [
                            _c(
                              VCardTitle,
                              { staticClass: "H4-Secondary-Center" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.meal_equivalent_tender_edit.name ||
                                      "Meal Equivalent Tender Edit"
                                  )
                                )
                              ]
                            ),
                            _c(
                              VForm,
                              {
                                ref: "tenderForm",
                                staticClass: "py-2 px-3",
                                staticStyle: {
                                  border: "solid 1px #757575",
                                  "margin-left": "25px",
                                  "margin-right": "25px"
                                }
                              },
                              [
                                _c(
                                  VLayout,
                                  { attrs: { row: "", wrap: "" } },
                                  [
                                    _c(
                                      VFlex,
                                      { attrs: { xs4: "" } },
                                      [
                                        _c(VTextField, {
                                          attrs: {
                                            label: "Tender ID",
                                            value: _vm.getTenderNumber(
                                              _vm.meal_equivalent_tender_edit
                                                .tender_number
                                            ),
                                            rules: _vm.tenderIdAndTypeRules(
                                              _vm.meal_equivalent_tender_edit
                                            )
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.updateTenderId(
                                                _vm.meal_equivalent_tender_edit,
                                                _vm.meal_equivalent_tender_edit
                                                  .index,
                                                $event
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(VFlex, { attrs: { xs8: "" } }),
                                    _c(
                                      VFlex,
                                      { attrs: { xs4: "" } },
                                      [
                                        _c(VTextField, {
                                          attrs: {
                                            label: "Max Swipes per Transaction",
                                            rules:
                                              _vm.maxSwipesPerTransactionRules,
                                            type: "number"
                                          },
                                          model: {
                                            value:
                                              _vm.meal_equivalent_tender_edit
                                                .max_swipes_per_transaction,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.meal_equivalent_tender_edit,
                                                "max_swipes_per_transaction",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "meal_equivalent_tender_edit.max_swipes_per_transaction"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(VFlex, { attrs: { xs8: "" } }),
                                    _c(
                                      VFlex,
                                      { attrs: { xs4: "" } },
                                      [
                                        _c(VSelect, {
                                          attrs: {
                                            value: _vm.tenderMenuTax,
                                            rules: _vm.taxRules,
                                            items: _vm.taxExemptionOptions,
                                            label: "Tax Exempt",
                                            placeholder:
                                              "Please select tax exemption"
                                          },
                                          on: {
                                            change: function($event) {
                                              _vm.tenderMenuTax = $event
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(VFlex, { attrs: { xs8: "" } }),
                                    _c(VFlex, { attrs: { xs12: "" } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "H6-Selected-On-Surface-High-Emphasis-Left"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Equivalency Time Ranges\n                "
                                          )
                                        ]
                                      )
                                    ]),
                                    _c(
                                      VFlex,
                                      { attrs: { xs12: "" } },
                                      [
                                        _c(
                                          VLayout,
                                          {
                                            staticStyle: {
                                              "padding-top": "25px"
                                            },
                                            attrs: { row: "", wrap: "" }
                                          },
                                          [
                                            _vm._l(
                                              _vm.meal_equivalent_tender_edit
                                                .rates,
                                              function(rate, index) {
                                                return [
                                                  _c(
                                                    VFlex,
                                                    {
                                                      key:
                                                        index + "remove_rate",
                                                      attrs: { xs1: "" }
                                                    },
                                                    [
                                                      _vm
                                                        .meal_equivalent_tender_edit
                                                        .rates.length > 1
                                                        ? _c(
                                                            VIcon,
                                                            {
                                                              staticStyle: {
                                                                cursor:
                                                                  "pointer",
                                                                "padding-top":
                                                                  "25px"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.removeTenderRate(
                                                                    index
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-close"
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    VFlex,
                                                    {
                                                      key: index + "hours",
                                                      attrs: { xs7: "" }
                                                    },
                                                    [
                                                      _c(
                                                        VLayout,
                                                        {
                                                          attrs: {
                                                            row: "",
                                                            wrap: ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            VFlex,
                                                            {
                                                              attrs: { xs6: "" }
                                                            },
                                                            [
                                                              _c(
                                                                "time-select",
                                                                {
                                                                  staticStyle: {
                                                                    width:
                                                                      "180px"
                                                                  },
                                                                  attrs: {
                                                                    label:
                                                                      "Start Time",
                                                                    value:
                                                                      rate.start,
                                                                    rules:
                                                                      _vm.requiredField,
                                                                    autocomplete:
                                                                      ""
                                                                  },
                                                                  on: {
                                                                    "update:value": function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.$set(
                                                                        rate,
                                                                        "start",
                                                                        $event
                                                                      )
                                                                    }
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            VFlex,
                                                            {
                                                              attrs: { xs6: "" }
                                                            },
                                                            [
                                                              _c(
                                                                "time-select",
                                                                {
                                                                  staticStyle: {
                                                                    width:
                                                                      "180px"
                                                                  },
                                                                  attrs: {
                                                                    label:
                                                                      "End Time",
                                                                    value:
                                                                      rate.end,
                                                                    rules:
                                                                      _vm.requiredField,
                                                                    autocomplete:
                                                                      ""
                                                                  },
                                                                  on: {
                                                                    "update:value": function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.$set(
                                                                        rate,
                                                                        "end",
                                                                        $event
                                                                      )
                                                                    }
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    VFlex,
                                                    {
                                                      key: index + "amount",
                                                      attrs: { xs3: "" }
                                                    },
                                                    [
                                                      _c("v-currency-field", {
                                                        attrs: {
                                                          label: "Amount",
                                                          value: _vm.getRateAmount(
                                                            rate.amount
                                                          ),
                                                          rules:
                                                            _vm.tenderRateAmountRules
                                                        },
                                                        on: {
                                                          input: function(
                                                            amount
                                                          ) {
                                                            return _vm.setRateAmount(
                                                              rate,
                                                              amount
                                                            )
                                                          }
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(VFlex, {
                                                    key: index + "spacing",
                                                    attrs: { xs1: "" }
                                                  })
                                                ]
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      VFlex,
                                      [
                                        _c(
                                          VBtn,
                                          {
                                            attrs: {
                                              flat: "",
                                              color: "primary"
                                            },
                                            on: { click: _vm.addRatesToTender }
                                          },
                                          [
                                            _c(VIcon, [_vm._v("mdi-plus")]),
                                            _vm._v(
                                              "add range\n                "
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              VCardActions,
                              { staticStyle: { padding: "15px" } },
                              [
                                _c(VSpacer),
                                _c(
                                  VBtn,
                                  {
                                    attrs: { color: "primary", flat: "" },
                                    on: {
                                      click:
                                        _vm.cancelMealEquivalentTenderChanges
                                    }
                                  },
                                  [_vm._v("cancel")]
                                ),
                                _c(
                                  VBtn,
                                  {
                                    attrs: { color: "primary" },
                                    on: { click: _vm.saveMealEquivalentTender }
                                  },
                                  [_vm._v("save")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _c("remove-dialog", {
        attrs: {
          title: "Remove  Meal Plan",
          descriptionLines: [
            "Removing cannot be undone. Any brands that use this meal plan type will no longer be able to use it.",
            "To continue with removal please enter REMOVE in the field below and continue."
          ],
          onConfirmHandler: _vm.remove,
          disabled: _vm.isSectionDisabled
        },
        model: {
          value: _vm.showRemoveDialog,
          callback: function($$v) {
            _vm.showRemoveDialog = $$v
          },
          expression: "showRemoveDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }