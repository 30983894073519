import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    [
      _c(VCardTitle, { attrs: { "primary-title": "" } }, [
        _c("div", { staticClass: "H5-Primary-Left" }, [_vm._v("Frictionless")])
      ]),
      _c(
        VLayout,
        {
          attrs: {
            column: "",
            "justify-center": "",
            "align-center": "",
            "fill-height": ""
          }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "Body-1-Black-High-Emphasis-Center Loyalty-Description-Messages"
            },
            [_vm._v("\n      Allow Frictionless?\n    ")]
          ),
          _c("toggle", {
            attrs: { disabled: _vm.isSectionDisabled },
            model: {
              value: _vm.frictionless_supportedProxy,
              callback: function($$v) {
                _vm.frictionless_supportedProxy = $$v
              },
              expression: "frictionless_supportedProxy"
            }
          }),
          _c("frictionless-dialog", {
            attrs: { dialog: _vm.showDialog },
            on: {
              submit: _vm.submitFrictionlessStore,
              close: _vm.frictionlessDialogClose
            }
          })
        ],
        1
      ),
      _vm.frictionless_supportedProxy && !_vm.isSiteConfig
        ? _c(
            "div",
            { staticStyle: { margin: "0px 20px 50px" } },
            [
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c(VSelect, {
                    attrs: {
                      label: "Frictionless Third-Party Partner",
                      rules: _vm.requiredRules,
                      items: _vm.getPartners()
                    },
                    model: {
                      value: _vm.frictionless_third_party_partnerProxy,
                      callback: function($$v) {
                        _vm.frictionless_third_party_partnerProxy = $$v
                      },
                      expression: "frictionless_third_party_partnerProxy"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }