<template>
  <v-card>
    <v-card-title primary-title>
      <div class="H5-Primary-Left">Loyalty Program</div>
    </v-card-title>
    <div v-if="!value.enabled">
      <v-layout column justify-center align-center fill-height>
        <v-img :src="require('@/assets/loyalty.svg')" height="145px" width="187px" />
        <div class="Body-1-Black-High-Emphasis-Center Loyalty-Description-Messages">
          Loyalty is not enabled for this brand
        </div>
        <div class="Subtitle-1-Black-Medium-Emphasis-Center Loyalty-Description-Messages">
          Would you like to enable Loyalty?
        </div>
        <toggle v-model="value.enabled" :disabled="isSectionDisabled" />
      </v-layout>
    </div>
    <div class="Loyalty-Content" style="padding: 24px; padding-top: 0px;" v-else>
      <v-layout>
        <v-flex>
          <v-text-field
            label="Program ID"
            v-model="value.provider_id"
            :rules="providerIdRules"
            class="Text-Field-Filled-Trailing-Icon-Pressed mb-3"
            :disabled="isSectionDisabled"
          />
        </v-flex>
        <v-flex>
          <v-layout justify-end>
            <v-btn
              color="primary"
              class="Remove-Button mt-4 mr-3"
              v-on:click="showRemoveDialog = true"
              :disabled="isSectionDisabled"
            >
              <v-icon class="mr-1" left>mdi-delete-outline</v-icon>REMOVE
            </v-btn>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout row-wrap class="Loyalty-Emails-Content mt-3 mb-5" v-if="type === SITE">
        <v-flex>
          <div
            v-if="!isSectionDisabled"
            v-on:click="showEmailDialog = true"
            class="Body-1-Primary-Left Loyalty-Emails-Link"
          >
            Manage Loyalty Emails
          </div>
          <div class="Caption-Black-Medium-Emphasis-Left">
            Loyalty Access:
            {{ numUsers === 0 ? 'Entire Site' : `${numUsers} User${numUsers > 1 ? 's' : ''}` }}
          </div>
        </v-flex>
      </v-layout>
    </div>
    <v-dialog
      persistent
      v-model="dialogVModelPlaceHolder"
      width="800px"
      height="600px"
      v-if="showEmailDialog"
    >
      <v-card>
        <v-card-title class="H4-Secondary-Center">Manage Loyalty Users</v-card-title>
        <v-form class="Email-Dialog-Content" ref="emailForm">
          <v-layout>
            <v-flex grow>
              <v-text-field
                label="User Email"
                v-model="newEmail"
                :rules="emailRules"
                v-on:keydown.enter="addEmail"
                class="mb-2"
              />
            </v-flex>
            <v-flex shrink py-2>
              <v-layout justify-end>
                <v-btn dark color="#0c62c6" v-on:click="addEmail">ADD</v-btn>
              </v-layout>
            </v-flex>
          </v-layout>
          <div class="Email-Dialog-List">
            <div v-if="newEmailList.length > 0">
              <div v-for="(l, index) in newEmailList" v-bind:key="index" class="Email-Cell">
                <v-layout row wrap align-center class="Body-1 pl-2">
                  <v-flex>{{ l.email }}</v-flex>
                  <v-flex>
                    <v-layout justify-end>
                      <v-btn flat color="grey" v-on:click="newEmailList.splice(index, 1)">
                        <v-icon class="mr-1" left>mdi-delete-outline</v-icon>REMOVE
                      </v-btn>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </div>
            </div>
            <div class="mt-4" v-else>
              <v-layout column justify-center align-center fill-height>
                <v-img :src="require('@/assets/loyalty-email.svg')" height="158px" width="179px" />
                <div class="Body-1-Black-High-Emphasis-Center Loyalty-Description-Messages">
                  Enter emails to allow on certain users
                </div>
                <div class="Subtitle-1-Black-Medium-Emphasis-Center Loyalty-Description-Messages">
                  to access the on-site loyalty program
                </div>
              </v-layout>
            </div>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn flat color="blue" v-on:click="cancelEmails()">CANCEL</v-btn>
            <v-btn flat color="blue" v-on:click="saveEmails()">SAVE</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <remove-dialog
      :title="'Remove Loyalty Program'"
      :descriptionLines="[
        'Removing cannot be undone. Any users who are using the loyalty program will no longer have access to the program and will lose all their accumulated points.',
        'To continue with deletion please enter REMOVE in the field below and continue.',
      ]"
      :onConfirmHandler="removeLoyalty"
      v-model="showRemoveDialog"
    />
  </v-card>
</template>

<script>
// TODO: FIX THIS USING PROP.SYNC
import cloneDeep from 'lodash/cloneDeep';

import removeDialog from '@/components/removeDialog';

import rules from '@/rules';
import { SITE, BRAND } from '@/constants';

export default {
  components: {
    removeDialog,
  },
  props: {
    value: {},
    type: {
      default: SITE,
    },
    isSectionDisabled: {
      type: Boolean,
    },
  },
  data: () => ({
    BRAND,
    SITE,
    providerIdRules: [rules.required('Please enter Provider ID')],
    emailRules: [rules.email('Please enter a valid Email')],
    showEmailDialog: false,
    showRemoveDialog: false,
    dialogVModelPlaceHolder: true,
    newEmail: null,
    newEmailList: [],
  }),
  computed: {
    numUsers() {
      try {
        return this.newEmailList.length;
      } catch (err) {
        return 0;
      }
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.$emit('input', this.value);
        this.newEmailList = cloneDeep(this.value.loyalty_emails || []);
      },
    },
  },
  methods: {
    addEmail() {
      if (this.newEmail && !this.emailRules.some((r) => r(this.newEmail) !== true)) {
        this.newEmailList.push({ email: this.newEmail });
        this.newEmail = null;
        this.$refs.emailForm.reset();
      }
    },
    cancelEmails() {
      this.showEmailDialog = false;
      this.newEmailList = JSON.parse(JSON.stringify(this.value.loyalty_emails || []));
      this.$refs.emailForm.reset();
    },
    saveEmails() {
      this.showEmailDialog = false;
      this.value = { ...this.value, loyalty_emails: [...cloneDeep(this.newEmailList)] };
      this.$refs.emailForm.reset();
    },
    removeLoyalty() {
      this.$emit('input', { enabled: false });
    },
  },
};
</script>

<style scoped>
.Loyalty-Description-Messages {
  padding-top: 14px;
}
.Loyalty-Content {
  padding-left: 14px;
  padding-right: 14px;
  overflow: hidden;
}
.Loyalty-Emails-Content {
  margin-top: 18px;
}
.Loyalty-Emails-Link {
  text-decoration: underline;
  margin-bottom: 8px;
  cursor: pointer;
}
/*
    Dialog
*/
.Email-Dialog-Content {
  padding-right: 14px;
  padding-left: 14px;
}
.Email-Dialog-List {
  height: 325px;
  width: 100%;
  overflow: scroll;
  border: solid 1px #757575;
}
.Email-Cell {
  width: 100%;
  height: 48px;
  border-radius: 1px;
  border-bottom: solid 1px #757575;
}
</style>
