import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    {
      staticClass: "tocLayout",
      class: _vm.customClass,
      attrs: { "align-left": "" }
    },
    [
      _c(
        VList,
        { staticClass: "tocStyle", attrs: { "once-line": "" } },
        [
          _vm._l(_vm.items, function(item) {
            var _obj, _obj$1
            return [
              !_vm.isExcludedItem(item.id)
                ? _c(
                    VListTile,
                    {
                      key: item.title,
                      class:
                        ((_obj = {}),
                        (_obj[_vm.style.itemSelected] =
                          item.id === _vm.selectedValue),
                        (_obj[_vm.style.itemNotSelected] =
                          item.id !== _vm.selectedValue),
                        _obj),
                      on: {
                        click: function($event) {
                          return _vm.scrollToContent(item.id)
                        }
                      }
                    },
                    [
                      _c(
                        VListTileContent,
                        [
                          _c(VListTileTitle, {
                            class:
                              ((_obj$1 = {}),
                              (_obj$1[_vm.style.itemSelectedLeft] =
                                item.id === _vm.selectedValue),
                              (_obj$1[_vm.style.itemNotSelectedLeft] =
                                item.id !== _vm.selectedValue),
                              _obj$1),
                            domProps: { innerHTML: _vm._s(item.title) }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }