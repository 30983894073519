<template>
  <div style="display: flex; align-items: center;">
    <v-text-field
      :disabled="disabled"
      v-model="activeLanguage"
      v-bind="attributes"
      v-on="listeners"
    >
      <template v-slot:append>
        <v-btn
          :disabled="disabled"
          flat
          small
          class="ma-0"
          style="min-width: 0px;"
          @click="nextLang"
        >
          {{ currentLang }}
        </v-btn>
      </template>
    </v-text-field>
    <v-menu open-on-hover offset-y v-if="value">
      <template v-slot:activator="{ on }">
        <v-btn :disabled="disabled" v-on="on" icon small flat class="ma-0">
          <v-icon v-on="on">
            mdi-web
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-tile v-for="lang of supported" :key="lang" @click="currentLang = lang">
          <v-list-tile-title>{{ lang | uppercase }}: {{ value[lang] }}</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: ['value', 'disabled'],
  data: () => ({
    defaultLang: 'en',
    currentLang: 'en',
    supported: ['en', 'fr'],
  }),
  computed: {
    activeLanguage: {
      get() {
        if (this.value === undefined || this.value === null) return '';
        if (typeof this.value !== 'object') return '';
        return this.value[this.currentLang];
      },
      set(v) {
        if (this.value === undefined) throw new Error('Cannot set language for undefined label');
        if (this.value === null || typeof this.value !== 'object') {
          this.$emit('input', { [this.currentLang]: v });
        } else if (this.value[this.currentLang]) {
          this.value[this.currentLang] = v;
        } else {
          this.$set(this.value, this.currentLang, v);
        }
      },
    },
    attributes() {
      // eslint-disable-next-line prefer-const
      let { label, ...attrs } = this.$attrs;
      if (label) label += ` (${this.currentLang})`;
      return { ...attrs, label };
    },
    listeners() {
      const { input, ...listeners } = this.$listeners;
      return listeners;
    },
  },
  methods: {
    nextLang() {
      this.currentLang =
        this.supported[this.supported.indexOf(this.currentLang) + 1] || this.supported[0];
    },
  },
};
</script>

<style></style>
