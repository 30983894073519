<template>
  <v-card>
    <v-container grid-list-xl>
      <v-layout row wrap align-baseline>
        <v-flex xs3>
          <div class="H5-Primary-Left">Meal Plan</div>
        </v-flex>
        <v-spacer />
        <v-btn
          :disabled="isSectionDisabled"
          flat
          color="secondary"
          v-if="mealplanObject && isMealplanConfigVisible"
          @click="showRemoveDialog = true"
        >
          <v-icon class="mr-1" left>mdi-delete-outline</v-icon>remove
        </v-btn>
      </v-layout>

      <v-layout
        column
        justify-center
        align-center
        fill-height
        v-if="isBrandMealplanMissing && !showBrandMealplanSetup"
      >
        <div
          class="Body-1-Black-High-Emphasis-Center Loyalty-Description-Messages"
          v-if="!siteMealplanObject"
        >
          Meal Plan is not configured for this brand at site level
        </div>
        <div
          class="Subtitle-1-Black-Medium-Emphasis-Center Loyalty-Description-Messages"
          v-if="siteMealplanObject"
        >
          Would you like to activate meal plan?
        </div>
        <toggle v-model="showBrandMealplanSetup" />
      </v-layout>

      <template v-if="isMealplanConfigVisible">
        <v-layout row wrap>
          <v-flex xs5>
            <v-select
              :items="types"
              label="Plan Type"
              placeholder="Choose a Plan"
              v-model="activeMealplanType"
              :disabled="isSectionDisabled || isBrandConfig"
            />
          </v-flex>
        </v-layout>

        <template v-if="mealplanObject">
          <v-layout row wrap>
            <v-flex xs5>
              <v-text-field
                @input="setPlanName"
                :value="getPlanName"
                :rules="planNameRules"
                :disabled="isPlanNameDisabled"
                label="Plan Name"
              />
            </v-flex>
          </v-layout>
          <template v-if="isSiteConfig && isCbord">
            <cbord
              :mealplan.sync="mealplanObject"
              :isSectionDisabled="isSectionDisabled"
              id="cbord-detail"
            />
          </template>
          <template v-if="isAtrium">
            <v-layout row wrap v-if="mealplanObject.private && mealplanObject.private.keystore">
              <v-flex xs12>
                <v-text-field
                  :disabled="isSectionDisabled"
                  label="Key"
                  v-model="mealplanObject.private.keystore.key"
                  :rules="requiredField"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  :disabled="isSectionDisabled"
                  label="API URL"
                  v-model="mealplanObject.private.keystore.API_URL"
                  :rules="requiredField"
                />
              </v-flex>
            </v-layout>
          </template>
          <template v-if="isBlackBoard && isSiteConfig">
            <v-layout row wrap v-if="mealplanObject.private && mealplanObject.private.keystore">
              <v-flex xs12>
                <v-text-field
                  :disabled="isSectionDisabled"
                  label="Host"
                  v-model="mealplanObject.private.keystore.host"
                  :rules="[...requiredField, ...isIPAddress]"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  :disabled="isSectionDisabled"
                  label="Port"
                  type="number"
                  v-model="mealplanObject.private.keystore.port"
                  :rules="requiredField"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  :disabled="isSectionDisabled"
                  label="AES key"
                  v-model="mealplanObject.private.keystore.aes_key"
                  :rules="requiredField"
                />
              </v-flex>
              <v-flex xs12>
                <v-select
                  :disabled="isSectionDisabled"
                  label="Vendor number"
                  :items="[118, 3600]"
                  v-model="mealplanObject.private.keystore.vendor_number"
                  :rules="requiredField"
                ></v-select>
              </v-flex>
            </v-layout>
          </template>

          <!-- heartland site template -->
          <template v-if="isHeartland">
            <v-layout row wrap v-if="mealplanObject.private && mealplanObject.private.keystore">
              <template v-if="isSiteConfig">
                <v-flex xs12>
                  <v-text-field
                    :disabled="isSectionDisabled"
                    :rules="[...requiredField, ...isAlphaNumeric]"
                    label="Operator ID"
                    v-model="mealplanObject.private.keystore.operator_id"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    :disabled="isSectionDisabled"
                    label="Operator Password"
                    v-model="mealplanObject.private.keystore.operator_password"
                    :rules="[...requiredField, ...isAlphaNumericAndSpecialChars]"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    :disabled="isSectionDisabled"
                    label="TouchNet API"
                    v-model="mealplanObject.private.keystore.api_url"
                    :rules="[...requiredField, ...isUrl]"
                  />
                </v-flex>
              </template>
            </v-layout>
            <v-layout>
              <v-flex xs12>
                <!-- We only support 1 tender for TouchNet,
                we do not collect tenders name or id, and the meal plan's name is used as the tenders name -->
                <v-select
                  label="Tax Exempt"
                  placeholder="Please select tax exemption"
                  @change="(v) => setHeartlandTender(v)"
                  :value="getHeartlandTenderTaxExempt"
                  :rules="taxRules"
                  :items="taxExemptionOptions"
                  :disabled="isSectionDisabled"
                />
              </v-flex>
            </v-layout>
          </template>

          <v-layout row wrap v-if="!isCbord && !isBlackBoard && !isAtrium">
            <v-flex xs12>
              <v-text-field
                label="Plan ID"
                :value="mealplanObject.id"
                @input="(id) => setMealplanId(id)"
                :rules="planIDRules"
                :disabled="isSectionDisabled"
              />
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex v-if="!isItc" xs12>
              <label-lang
                :disabled="isSectionDisabled"
                v-model="mealplanNote"
                label="Additional Instructions"
              />
            </v-flex>
            <v-layout v-if="isSiteConfig && isItc">
              <v-flex xs12 style="padding: 0 3.25% 0 3.25%">
                <v-text-field
                  v-model="mealplanObject.private.keystore.certificate.name"
                  @click="$refs.certificate.click()"
                  :rules="requiredField"
                  :disabled="isSectionDisabled"
                  label="Certificate"
                />
                <input
                  v-show="false"
                  @change="handleItcCertificateUpload"
                  type="file"
                  ref="certificate"
                  accept=".pfx"
                />
                <v-text-field
                  v-model="mealplanObject.private.keystore.certificate.password"
                  :rules="requiredField"
                  :disabled="isSectionDisabled"
                  label="Certificate Password"
                />
                <v-text-field
                  v-model="mealplanObject.private.keystore.endpoint"
                  :rules="[...requiredField, ...isUrl]"
                  :disabled="isSectionDisabled"
                  label="Connection URL"
                />
                <v-text-field
                  v-model="mealplanObject.private.keystore.vendor_id"
                  :rules="[...requiredField, ...isAlphaNumeric]"
                  :disabled="isSectionDisabled"
                  label="Vendor ID"
                />
                <v-text-field
                  v-model="mealplanObject.private.keystore.webview"
                  :rules="[...requiredField, ...isUrl]"
                  :disabled="isSectionDisabled"
                  label="Webview URL"
                />
              </v-flex>
            </v-layout>
            <v-flex xs12>
              <v-text-field
                v-if="!allowMultipleTerminalIds"
                label="Meal Plan Terminal ID"
                v-model="terminalId"
                :disabled="isSectionDisabled"
                :rules="terminalIdRules"
              />
              <template v-if="allowMultipleTerminalIds">
                <v-combobox
                  v-model="terminalId"
                  label="Meal Plan Terminal ID"
                  multiple
                  small-chips
                  v-if="isSiteConfig"
                  :rules="terminalIdRules"
                >
                </v-combobox>
                <v-select
                  v-model="terminalId"
                  :items="siteTerminalIds"
                  label="Meal Plan Terminal ID"
                  placeholder="Select Terminal IDs"
                  v-if="isBrandConfig"
                  :disabled="isSectionDisabled"
                />
              </template>
            </v-flex>
          </v-layout>

          <template v-if="!isHeartland">
            <v-layout row wrap>
              <v-flex
                v-for="(tender, index) of tenders"
                :key="index"
                class="display-flex align-center"
              >
                <v-flex xs1>
                  <v-icon
                    style="cursor: pointer"
                    v-if="shouldAllowRemoveTender"
                    @click="removeTender(index)"
                    >mdi-close</v-icon
                  >
                </v-flex>
                <v-flex xs5>
                  <v-select
                    @input="setTenderType(tender, $event)"
                    :value="tender.type"
                    :items="tenderTypesProxy"
                    :rules="tenderIdAndTypeRules(tender)"
                    :disabled="isSectionDisabled"
                    label="Tender Type"
                    placeholder="Please select Tender Type"
                  />
                </v-flex>
                <v-flex v-if="!isItc" xs6>
                  <v-text-field
                    v-model="tender.name"
                    :rules="tenderNameRules"
                    :disabled="isSectionDisabled"
                    label="Name of Tender"
                    placeholder="Please insert name of tender"
                  />
                </v-flex>
                <v-flex v-if="shouldDisplayTenderId(tender.type)" xs6 row wrap>
                  <v-text-field
                    label="Tender ID"
                    :value="getTenderId(tender)"
                    @input="updateTenderId(tender, index, $event)"
                    :rules="[...tenderIdAndTypeRules(tender), ...tenderIdRules(tender)]"
                    :disabled="isSectionDisabled"
                  />
                </v-flex>
                <v-flex xs6 v-if="tender.type !== TENDER_TYPES.MEAL_EXCHANGE">
                  <v-select
                    label="Tax Exempt"
                    placeholder="Please select tax exemption"
                    :value="getTenderTaxExempt(tender, index)"
                    @input="setTenderTaxExempt(tender, index, $event)"
                    :rules="taxRules"
                    :items="taxExemptionOptions"
                    :disabled="isSectionDisabled"
                  />
                </v-flex>
                <v-flex v-if="shouldDisplayTenderSetup(tender.type)" shrink>
                  <v-btn
                    flat
                    color="primary"
                    @click="setupTender(tender, index)"
                    :disabled="isSectionDisabled"
                    >Tender Setup</v-btn
                  >
                </v-flex>
              </v-flex>
            </v-layout>
            <v-flex>
              <v-btn flat color="primary" @click="addTender" :disabled="isSectionDisabled">
                <v-icon>mdi-plus</v-icon>add another tender type
              </v-btn>
            </v-flex>
          </template>

          <!-- mx/meq brand toggles -->
          <template v-if="isBrandConfig">
            <v-layout row v-if="isCbord">
              <v-flex>
                <h5 class="Subtitle-1-Black-Medium-Emphasis-Left">
                  Meal Equivalency Station ($ Value)?
                </h5>
                <toggle
                  :position="'start'"
                  class="ml-0"
                  stylingClass="my-2"
                  v-model="mealplanObject.isBrandMEQ"
                  :disabled="mealplanObject.isBrandMX || !hasMEQTenders"
                />
              </v-flex>
            </v-layout>
            <v-layout row v-if="isCbord || isBlackBoard">
              <v-flex>
                <h5 class="Subtitle-1-Black-Medium-Emphasis-Left">Meals Station (“Meal” Value)?</h5>
                <toggle
                  :position="'start'"
                  class="ml-0"
                  stylingClass="my-2"
                  v-model="mealplanObject.isBrandMX"
                  :disabled="mealplanObject.isBrandMEQ || !hasMXTenders"
                />
              </v-flex>
            </v-layout>
          </template>

          <template v-if="isDomainManagementVisible">
            <div
              v-if="!isSectionDisabled"
              class="Body-1-Primary-Left Domain-Emails-Link"
              @click="openDomainDialog"
            >
              Manage Meal Plan Domains
            </div>
            <div class="Caption-Black-Medium-Emphasis-Left">
              Mealplan Access:
              <template
                v-if="
                  !mealplanObject.valid_email_domains || !mealplanObject.valid_email_domains.length
                "
                >All Domains</template
              >
              <template v-else>{{ mealplanObject.valid_email_domains.length }} Domain(s)</template>
            </div>
          </template>
        </template>
        <v-dialog v-model="dialog" width="800">
          <v-card>
            <v-card-title class="H4-Secondary-Center">Manage Meal Plan Domains</v-card-title>
            <v-form ref="form" class="pa-4">
              <v-layout>
                <v-flex grow>
                  <v-text-field
                    label="Email Domain"
                    v-model="domainInput"
                    :rules="domainRules"
                    class="mb-2"
                    v-on:keydown.enter="addDomain"
                  />
                </v-flex>
                <v-flex shrink py-2>
                  <v-layout justify-end>
                    <v-btn dark color="#0c62c6" v-on:click="addDomain">ADD</v-btn>
                  </v-layout>
                </v-flex>
              </v-layout>
              <div class="Domain-Dialog-List">
                <div v-if="tempDomains && tempDomains.length > 0">
                  <div v-for="(d, index) in tempDomains" v-bind:key="index" class="Domain-Cell">
                    <v-layout row wrap align-center class="Body-1 pl-2">
                      <v-flex>{{ d }}</v-flex>
                      <v-flex>
                        <v-layout justify-end>
                          <v-btn flat color="grey" v-on:click="tempDomains.splice(index, 1)">
                            <v-icon class="mr-1" left>mdi-delete-outline</v-icon>REMOVE
                          </v-btn>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </div>
                </div>
                <div class="mt-4" v-else>
                  <v-layout column justify-center align-center fill-height>
                    <v-img
                      :src="require('@/assets/loyalty-email.svg')"
                      height="158px"
                      width="179px"
                    />
                    <div class="Body-1-Black-High-Emphasis-Center Loyalty-Description-Messages">
                      Enter Email Domains to allow on certain users
                    </div>
                    <div
                      class="Subtitle-1-Black-Medium-Emphasis-Center Loyalty-Description-Messages"
                    >
                      to register Meal Plan
                    </div>
                  </v-layout>
                </div>
              </div>
            </v-form>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" flat @click="dialog = false">cancel</v-btn>
              <v-btn color="primary" flat @click="saveDomains">save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="meal_equivalent_dialog" width="850">
          <v-card v-if="!!meal_equivalent_tender_edit">
            <v-card-title class="H4-Secondary-Center">{{
              meal_equivalent_tender_edit.name || 'Meal Equivalent Tender Edit'
            }}</v-card-title>
            <v-form
              ref="tenderForm"
              class="py-2 px-3"
              style="border: solid 1px #757575; margin-left: 25px; margin-right: 25px"
            >
              <v-layout row wrap>
                <v-flex xs4>
                  <v-text-field
                    label="Tender ID"
                    :value="getTenderNumber(meal_equivalent_tender_edit.tender_number)"
                    @input="
                      updateTenderId(
                        meal_equivalent_tender_edit,
                        meal_equivalent_tender_edit.index,
                        $event,
                      )
                    "
                    :rules="tenderIdAndTypeRules(meal_equivalent_tender_edit)"
                  ></v-text-field>
                </v-flex>
                <v-flex xs8 />
                <v-flex xs4>
                  <v-text-field
                    label="Max Swipes per Transaction"
                    v-model="meal_equivalent_tender_edit.max_swipes_per_transaction"
                    :rules="maxSwipesPerTransactionRules"
                    type="number"
                  ></v-text-field>
                </v-flex>
                <v-flex xs8 />
                <v-flex xs4>
                  <v-select
                    @change="tenderMenuTax = $event"
                    :value="tenderMenuTax"
                    :rules="taxRules"
                    :items="taxExemptionOptions"
                    label="Tax Exempt"
                    placeholder="Please select tax exemption"
                  />
                </v-flex>
                <v-flex xs8 />
                <v-flex xs12>
                  <div class="H6-Selected-On-Surface-High-Emphasis-Left">
                    Equivalency Time Ranges
                  </div>
                </v-flex>
                <v-flex xs12>
                  <v-layout row wrap style="padding-top: 25px">
                    <template v-for="(rate, index) of meal_equivalent_tender_edit.rates">
                      <v-flex xs1 :key="index + 'remove_rate'">
                        <v-icon
                          style="cursor: pointer; padding-top: 25px"
                          v-if="meal_equivalent_tender_edit.rates.length > 1"
                          @click="removeTenderRate(index)"
                          >mdi-close</v-icon
                        >
                      </v-flex>
                      <v-flex xs7 :key="index + 'hours'">
                        <v-layout row wrap>
                          <v-flex xs6>
                            <time-select
                              :label="'Start Time'"
                              v-bind:value.sync="rate.start"
                              :rules="requiredField"
                              style="width: 180px"
                              autocomplete
                            ></time-select>
                          </v-flex>
                          <v-flex xs6>
                            <time-select
                              :label="'End Time'"
                              v-bind:value.sync="rate.end"
                              :rules="requiredField"
                              style="width: 180px"
                              autocomplete
                            ></time-select>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs3 :key="index + 'amount'">
                        <v-currency-field
                          label="Amount"
                          @input="(amount) => setRateAmount(rate, amount)"
                          :value="getRateAmount(rate.amount)"
                          :rules="tenderRateAmountRules"
                        />
                      </v-flex>
                      <v-flex xs1 :key="index + 'spacing'" />
                    </template>
                  </v-layout>
                </v-flex>
                <v-flex>
                  <v-btn flat color="primary" @click="addRatesToTender">
                    <v-icon>mdi-plus</v-icon>add range
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-form>
            <v-card-actions style="padding: 15px">
              <v-spacer></v-spacer>
              <v-btn color="primary" flat @click="cancelMealEquivalentTenderChanges">cancel</v-btn>
              <v-btn color="primary" @click="saveMealEquivalentTender">save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-container>
    <remove-dialog
      :title="'Remove  Meal Plan'"
      :descriptionLines="[
        `Removing cannot be undone. Any brands that use this meal plan type will no longer be able to use it.`,
        'To continue with removal please enter REMOVE in the field below and continue.',
      ]"
      :onConfirmHandler="remove"
      v-model="showRemoveDialog"
      :disabled="isSectionDisabled"
    />
  </v-card>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import ID from '@compassdigital/id';
import { mapMutations, mapGetters } from 'vuex';
import removeDialog from '@/components/removeDialog';
import labelLang from '@/components/labelLang';
import timeSelect from '@/components/timeSelect';
import cbord from '@/components/cbord';
import MealplanHelperModule from '@/helpers/mealplan-helper-module';
import {
  MEALPLAN_TYPES,
  TENDER_TYPES,
  BRAND,
  SITE,
  DEFAULT_VENDOR_IDs,
  DEFAULT_MEALPLAN_NAMES,
} from '@/constants';
import rules from '@/rules';

export default {
  props: [
    'mealplan',
    'isSectionDisabled',
    'configLevel',
    'sitePublicConfig',
    'sitePrivateConfig',
    'siteId',
  ],
  components: {
    removeDialog,
    labelLang,
    timeSelect,
    cbord,
  },
  data: () => ({
    MEALPLAN_TYPES,
    TENDER_TYPES,
    BRAND,
    SITE,
    types: [
      {
        text: 'Transact Premise',
        value: MEALPLAN_TYPES.BLACKBOARD,
      },
      {
        text: 'Transact SaaS',
        value: MEALPLAN_TYPES.BLACKBOARD_SAAS,
      },
      {
        text: 'Cbord Web',
        value: MEALPLAN_TYPES.CBORD,
      },
      {
        text: 'Itc Standard',
        value: MEALPLAN_TYPES.ITCSTANDARD,
      },
      {
        text: 'Itc Dietary Restriction',
        value: MEALPLAN_TYPES.ITCDIETARYRESTRICTION,
      },
      {
        text: 'Itc Lunch Box',
        value: MEALPLAN_TYPES.ITCLUNCHBOX,
      },
      {
        text: 'ITC SSO',
        value: MEALPLAN_TYPES.ITCSSO,
      },
      {
        text: 'TouchNet (Heartland)',
        value: MEALPLAN_TYPES.HEARTLAND,
      },
      {
        text: 'Atrium',
        value: MEALPLAN_TYPES.ATRIUM,
      },
      {
        text: 'CBORD DIRECT',
        value: MEALPLAN_TYPES.CBORD_DIRECT,
      },
    ],
    planNameRules: [rules.required('Plan name is required')],
    planIDRules: [rules.required('Plan ID is required')],
    noteRules: [rules.required('Additional instructions is required')],
    tenderRules: [rules.required('Tender type is required')],
    domainRules: [rules.domain('Invalid Email Domain')],
    tenderRateAmountRules: [
      rules.required('Amount is a required property'),
      rules.greaterThan(0, 'Amount must be greater than 0'),
    ],
    maxSwipesPerTransactionRules: [
      rules.required('Max Swipes is a required property'),
      rules.greaterThan(0, 'Max Swipes must be greater than 0'),
    ],
    requiredField: [rules.required('This is a required field')],
    taxRules: [rules.required('Tax Exemption is a required field', [true, false])],
    numbersOnly: [rules.numeric()],
    isIPAddress: [rules.isIPAddress],
    isUrl: [rules.url('Valid connection url is required')],
    isAlphaNumeric: [rules.isAlphaNumeric],
    isAlphaNumericAndSpecialChars: [rules.isAlphaNumericAndSpecialChars],
    domainInput: '',
    taxExemptionOptions: [
      {
        text: 'Yes',
        value: true,
      },
      {
        text: 'No',
        value: false,
      },
    ],
    tenderTypes: [
      {
        text: 'Meal Equivalency',
        value: TENDER_TYPES.MEAL_EQUIVALENT,
      },
      {
        text: 'Declining Balance',
        value: TENDER_TYPES.DECLINING_BALANCE,
      },
      {
        text: 'Meals',
        value: TENDER_TYPES.MEAL_EXCHANGE,
      },
    ],
    tempDomains: [],
    dialog: false,
    meal_equivalent_dialog: false,
    showRemoveDialog: false,
    note: {},
    cbordTemplate: {
      private: {
        keystore: {
          mealplan: {
            uuid: null,
            name: null,
            short_name: null,
            auth_url: null,
            auth_type: null,
            program_name: null,
            primary_color: null,
            type: null,
          },
        },
      },
    },
    atriumTemplate: {
      private: {
        keystore: {
          API_URL: null,
          key: null,
        },
        version: 1,
        id: null,
        name: MEALPLAN_TYPES.ATRIUM,
        config: {
          version: '1',
          tenders: [
            {
              id: null,
              tax_exempt: null,
              name: null,
              type: null,
              tender_number: null,
              max_swipes_per_transaction: null,
              rates: [
                {
                  hours: null,
                  amount: null,
                },
              ],
            },
          ],
          terminal_id: null,
        },
      },
      public: {
        name: MEALPLAN_TYPES.ATRIUM,
        type: MEALPLAN_TYPES.ATRIUM,
        id: null,
        tenders: [
          {
            id: null,
            tax_exempt: null,
            name: null,
            type: null,
            tender_number: null,
            max_swipes_per_transaction: null,
            rates: [
              {
                hours: null,
                amount: null,
              },
            ],
          },
        ],
      },
    },
    blackboardTemplate: {
      private: {
        keystore: {
          host: undefined,
          port: undefined,
          aes_key: undefined,
          vendor_number: undefined,
          manual_id_entry: 'T',
        },
        id: null,
        name: MEALPLAN_TYPES.BLACKBOARD,
      },
      public: {
        name: MEALPLAN_TYPES.BLACKBOARD,
        type: MEALPLAN_TYPES.BLACKBOARD,
        id: null,
        terminal_id: null,
        tenders: [
          {
            id: null,
            tax_exempt: null,
            name: null,
            type: null,
          },
        ],
      },
    },
    itcTemplate: {
      private: {
        keystore: {
          certificate: {
            name: null,
            encoded_file: null,
            password: null,
          },
          endpoint: null,
          vendor_id: DEFAULT_VENDOR_IDs.ITC,
          webview: null,
          device_id: null,
        },
        id: null,
        name: DEFAULT_MEALPLAN_NAMES.ITC,
        type: null,
      },
      public: {
        id: null,
        type: null,
        name: DEFAULT_MEALPLAN_NAMES.ITC,
        tenders: [
          {
            id: null,
            tax_exempt: null,
            type: TENDER_TYPES.DECLINING_BALANCE,
          },
        ],
      },
    },
    heartlandTemplate: {
      private: {
        id: null,
        type: MEALPLAN_TYPES.HEARTLAND,
        name: null,
        keystore: {
          operator_id: undefined,
          operator_password: undefined,
          api_url: undefined,
        },
      },
      public: {
        id: null,
        type: MEALPLAN_TYPES.HEARTLAND,
        name: null,
        tenders: [
          {
            id: '1',
            tax_exempt: false,
            type: TENDER_TYPES.DECLINING_BALANCE,
          },
        ],
        terminal_id: null,
      },
    },
    meal_equivalent_tender_edit: {},
    showBrandMealplanSetup: false,
  }),
  methods: {
    ...mapMutations('validateMeqSetup,', ['setInValidMeq']),
    ...MealplanHelperModule,
    remove() {
      this.activeMealplanType = null;
    },
    getTenderNumber(tender_number) {
      return tender_number && ID(tender_number).id;
    },
    removeTender(i) {
      if (this.isAtrium) {
        this.mealplanObject.private.config.tenders.splice(i, 1);
        this.mealplanObject.public.tenders.splice(i, 1);
      } else this.mealplanObject.tenders.splice(i, 1);
      this.$store.commit('validateMeqSetup/setInValidMeq', i);
    },
    addTender() {
      if (this.isAtrium) {
        this.mealplanObject.private.config.tenders.push({});
        this.mealplanObject.public.tenders.push({
          tax_exempt: null,
          id: null,
        });
      } else {
        if (!this.mealplanObject.tenders || !Array.isArray(this.mealplanObject.tenders)) {
          this.$set(this.mealplanObject, 'tenders', []);
        }
        this.mealplanObject.tenders.push({
          name: '',
          type: TENDER_TYPES.DECLINING_BALANCE,
        });
      }
    },
    getTenderTaxExempt(tender, index) {
      if (this.isAtrium) {
        const publicTender = this.getAtriumPublicTenderByIdIndex(tender.tender_number, index);
        return publicTender && publicTender.tax_exempt;
      }
      if (this.isItc) {
        if (!tender.tax_exempt) {
          tender.tax_exempt = false;
        }
      }
      return tender.tax_exempt;
    },
    setTenderTaxExempt(tender, index, value) {
      if (this.isAtrium) {
        const publicTenderToUpdate = this.getAtriumPublicTenderByIdIndex(
          tender.tender_number,
          index,
        );
        this.$set(publicTenderToUpdate, 'tax_exempt', value);
        return;
      }
      this.$set(tender, 'tax_exempt', value);
    },
    getTenderId(tender) {
      if (this.isAtrium) return this.getTenderNumber(tender.tender_number);
      return tender.id;
    },
    updateTenderId(tender, index, id) {
      if (!this.isAtrium && !this.isBlackBoard) {
        this.$set(tender, 'id', id);
        this.$set(
          tender,
          'tender_number',
          ID('mealplan', this.getMealplanProviderName(this.activeMealplanType), 'tender', id),
        );
        if (this.isItc) {
          this.$set(tender, 'name', id);
        }
        return;
      }

      if (this.isAtrium) {
        const publicTenderToUpdate = this.getAtriumPublicTenderByIdIndex(
          tender.tender_number,
          index,
        );
        this.$set(publicTenderToUpdate, 'id', id);
      } else if (this.isBlackBoard) {
        this.$set(tender, 'id', id);
      }

      this.$set(
        tender,
        'tender_number',
        ID('mealplan', this.getMealplanProviderName(this.activeMealplanType), 'tender', id),
      );
      if (
        this.meal_equivalent_tender_edit &&
        Object.keys(this.meal_equivalent_tender_edit).length > 0
      ) {
        this.$set(
          this.meal_equivalent_tender_edit,
          'tender_number',
          ID('mealplan', this.getMealplanProviderName(this.activeMealplanType), 'tender', id),
        );
      }
    },
    setPlanName(name) {
      this.mealplanObject.name = name;

      if (this.isHeartland) {
        // We only support 1 tender for TouchNet,
        // we do not collect tenders name or id, and the meal plan's name is used as the tenders name
        this.mealplanObject.private.name = name;
        this.mealplanObject.tenders[0].name = name;
      }
    },
    getAtriumPublicTenderByIdIndex(encoded_id, index) {
      const decodedTenderId = ID(encoded_id) && ID(encoded_id).id;
      const publicTenders = decodedTenderId
        ? this.mealplanObject.public.tenders.filter((t) =>
            t.tender_number ? t.tender_number === encoded_id : t.id === decodedTenderId,
          )
        : [this.mealplanObject.public.tenders[index]];
      const publicTender =
        publicTenders.length > 1
          ? this.mealplanObject.public.tenders.find((t, i) =>
              t.tender_number
                ? t.tender_number === encoded_id && i === index
                : t.id === decodedTenderId && i === index,
            )
          : publicTenders[0];
      return publicTender;
    },
    setMealplanId(id) {
      if (this.isAtrium) {
        this.mealplanObject.private.config.id = id;
        this.mealplanObject.public.id = id;
      }
      if (this.isBlackBoard && this.isSiteConfig) {
        this.mealplanObject.private.id = id;
      }
      this.mealplanObject.id = id;
    },
    removeTenderRate(i) {
      this.meal_equivalent_tender_edit.rates.splice(i, 1);
    },
    addRatesToTender() {
      this.meal_equivalent_tender_edit.rates.push({
        hours: null,
        amount: null,
      });
    },
    setTenderType(tender, type) {
      if (tender.type === type) return;
      this.$set(tender, 'type', type);
      if (this.isAtrium || this.isBlackBoard) {
        if (type === TENDER_TYPES.DECLINING_BALANCE) {
          if (tender.rates) this.$delete(tender, 'rates');
          this.$delete(tender, 'max_swipes_per_transaction');
        } else {
          this.$set(tender, 'max_swipes_per_transaction', null);
          this.$set(tender, 'rates', [{ hours: null, amount: null }]);
        }
        this.$store.commit('validateMeqSetup/setInValidMeq', tender);
      }
      if (this.isCbord && type === TENDER_TYPES.MEAL_EXCHANGE) {
        this.$set(tender, 'tax_exempt', true);
      }
    },
    createMealplan(v = '') {
      if (this.mealplanObject && this.mealplanObject.type === v) return undefined;
      const mealplan = this.createMealplanStorageObject(v);
      if (mealplan.name === MEALPLAN_TYPES.CBORD_DIRECT) {
        mealplan.name = MEALPLAN_TYPES.CBORD;
      }
      return this.$emit('update:mealplan', [mealplan]);
    },
    createMealplanStorageObject(mealplanType) {
      const default_config = {
        id: null,
        type: mealplanType,
        name: this.defaultMealplanName(mealplanType),
        tenders: [{ type: TENDER_TYPES.DECLINING_BALANCE }],
        note: cloneDeep(this.note),
        valid_email_domains: [],
      };
      if (this.isTypeItc(mealplanType)) {
        const id = ID(
          'mealplan',
          this.getMealplanProviderName(mealplanType),
          'mealplan',
          this.siteId,
        );
        const mealplanObject = cloneDeep(this.itcTemplate.public);
        mealplanObject.type = mealplanType;
        mealplanObject.id = id;
        this.itcTemplate.private.id = id;
        return mealplanObject;
      }
      if (this.isTypeHeartland(mealplanType)) {
        default_config.tenders[0].id = '1';
        default_config.tenders[0].tax_exempt = false;
        default_config.tenders[0].tender_number = ID('mealplan', mealplanType, 'tender', '1');
        return default_config;
      }
      return default_config;
    },
    convertHours(start, end) {
      return `${start}-${end}`;
    },
    getHoursStartEnd(hours) {
      if (!hours) return { start: null, end: null };
      const [start, end] = hours.split('-');
      return { start, end };
    },
    setRateAmount(rate, amount) {
      this.$set(rate, 'amount', Math.round(Number(amount) * 100));
    },
    getRateAmount(rate) {
      return Number(rate) / 100;
    },
    setupTender(tender, index) {
      let tender_number;
      if (this.isAtrium) {
        tender_number = this.mealplanObject?.public?.tenders[index]?.tender_number;
      } else {
        ({ tender_number } = this.mealplanObject.tenders[index]);
      }

      const rateTender = {
        ...tender,
        index,
        tender_number,
      };

      if (!rateTender.rates) {
        rateTender.rates = [
          {
            amount: null,
            hours: null,
          },
        ];
      }

      rateTender.rates.forEach((r) => {
        const { start, end } = this.getHoursStartEnd(r.hours);
        this.$set(r, 'start', start);
        this.$set(r, 'end', end);
      });
      this.$set(this, 'meal_equivalent_tender_edit', rateTender);
      this.meal_equivalent_dialog = true;
    },
    saveMealEquivalentTender() {
      if (this.$refs.tenderForm.validate()) {
        const convertedHours = this.meal_equivalent_tender_edit.rates.map((r) =>
          this.convertHours(r.start, r.end),
        );

        const errorValidateHours = this.validateHours(convertedHours);
        if (errorValidateHours) {
          this.$toast.error(errorValidateHours);
          return;
        }

        this.meal_equivalent_tender_edit.rates.forEach((r) => {
          this.$set(r, 'hours', this.convertHours(r.start, r.end));
          this.$delete(r, 'start');
          this.$delete(r, 'end');
        });

        const tenderIndex = this.meal_equivalent_tender_edit.index;

        let tenders;
        if (this.isAtrium) {
          ({ tenders } = this.mealplanObject.public);
        } else {
          ({ tenders } = this);
        }

        this.$set(tenders, tenderIndex, {
          id: this.isAtrium ? tenders[tenderIndex].id : this.meal_equivalent_tender_edit.id,
          tax_exempt: this.meal_equivalent_tender_edit.tax_exempt,
          tender_number: this.meal_equivalent_tender_edit.tender_number,
          name: this.meal_equivalent_tender_edit.name,
          type: TENDER_TYPES.MEAL_EQUIVALENT,
          max_swipes_per_transaction: Number(
            this.meal_equivalent_tender_edit.max_swipes_per_transaction,
          ),
          rates: this.meal_equivalent_tender_edit.rates,
        });
        const meqTenderInfo = this.meal_equivalent_tender_edit;
        this.cleanMealEquivalentTender();
        this.$store.commit('validateMeqSetup/setInValidMeq', meqTenderInfo);
      } else {
        this.$toast.error('Please fix the errors');
      }
    },
    cancelMealEquivalentTenderChanges() {
      const meqTenderInfo = { ...this.meal_equivalent_tender_edit };
      this.cleanMealEquivalentTender();
      this.$store.commit('validateMeqSetup/setInValidMeq', meqTenderInfo);
    },
    cleanMealEquivalentTender() {
      this.$set(this, 'meal_equivalent_tender_edit', {});
      this.meal_equivalent_dialog = false;
    },
    openDomainDialog() {
      this.dialog = true;
      this.tempDomains = cloneDeep(this.mealplanObject.valid_email_domains) || [];
    },
    addDomain() {
      if (this.$refs.form.validate()) {
        this.tempDomains.push(this.domainInput);
        this.domainInput = '';
        this.$refs.form.reset();
      }
    },
    saveDomains() {
      this.mealplanObject.valid_email_domains = this.tempDomains;
      this.dialog = false;
    },
    validateHours(hours) {
      // create an array to hold 15 minute windows for a period of 24 hours
      const quarterIncrements = new Array(96).fill(0);

      for (let j = 0; j < hours.length; j += 1) {
        const h = hours[j].split('-');
        const hbeg = Number(h[0].split(':')[0]);
        const mbeg = Number(h[0].split(':')[1]);
        const hend = Number(h[1].split(':')[0]);
        const mend = Number(h[1].split(':')[1]);

        // check if 24 hours are covered within one slot, if yes then throw an error if more than one slots are defined
        if (hbeg === hend && mbeg === mend) {
          for (let i = 0; i < quarterIncrements.length; i += 1) {
            if (quarterIncrements[i]) return 'Hours should not overlap, please fix it';
            quarterIncrements[i] = 1;
          }
          return null;
        }

        // get the start and end 15 minute windows for the the slot
        const start15MinInterval = Math.round(hbeg * 4 + mbeg / 15);
        const end15MinInterval = Math.round(hend * 4 + mend / 15);

        // mark all the 15 minute windows that this slot covers
        let current15MinInterval = start15MinInterval;
        let difference =
          end15MinInterval < start15MinInterval
            ? 96 - Math.abs(end15MinInterval - start15MinInterval)
            : Math.abs(end15MinInterval - start15MinInterval);
        while (difference > 0) {
          if (quarterIncrements[current15MinInterval])
            return 'Hours should not overlap, please fix it';
          quarterIncrements[current15MinInterval] = 1;
          current15MinInterval += 1;
          if (current15MinInterval === quarterIncrements.length) {
            current15MinInterval = 0;
          }
          difference -= 1;
        }
      }

      // throw an error if not all 15 minute windows are marked for the period of 24 hours
      return quarterIncrements.filter((q) => !!q).length !== 96
        ? 'Hours should span 24 hours'
        : null;
    },
    handleItcCertificateUpload(e) {
      const { files } = e.target;
      const fileReader = new FileReader();
      if (files[0]) {
        fileReader.readAsDataURL(files[0]);
        fileReader.addEventListener('load', () => {
          const splitFile = fileReader.result.split('base64,');
          const encoded_file = splitFile.length > 1 ? splitFile[1] : splitFile[0];
          this.mealplanObject.private.keystore.certificate.encoded_file = encoded_file;
          this.mealplanObject.private.keystore.certificate.name = files[0].name;
          if (
            !this.mealplanObject.private.keystore.certificate.encoded_file ||
            !this.mealplanObject.private.keystore.certificate.name
          ) {
            this.$toast.error('could not upload certificate');
          }
        });
      } else {
        this.$toast.error('could not upload certificate');
      }
    },
    defaultMealplanName(mealplanType) {
      if (this.isTypeBlackboard(mealplanType)) return MEALPLAN_TYPES.TRANSACT;
      if (this.isTypeHeartland(mealplanType)) return null;

      return mealplanType;
    },
    shouldDisplayTenderId(tenderType) {
      return !(
        tenderType === TENDER_TYPES.MEAL_EQUIVALENT &&
        (this.isAtrium || this.isBlackBoard || this.isCbord)
      );
    },
    shouldDisplayTenderSetup(tenderType) {
      return tenderType === TENDER_TYPES.MEAL_EQUIVALENT;
    },
    setHeartlandTender(taxExemptValue) {
      if (!this.mealplanObject?.tenders?.[0]) {
        this.mealplanObject.tenders = [];
        this.mealplanObject.tenders.push({ ...this.heartlandTemplate.public.tenders[0] });
      }
      this.mealplanObject.tenders[0].tax_exempt = taxExemptValue;
      this.$emit('update:mealplan', [this.mealplanObject]);
    },
    tenderIdAndTypeRules(tender) {
      const _rules = [rules.required('This is a required field')];

      const checkDuplicate = () => {
        const tenderList = this.mealplanObject?.tenders || [];
        const duplicate = tenderList.filter((t) => t.id === tender.id && t.type === tender.type);
        return duplicate.length > 1 ? 'Tender is already defined' : true;
      };
      _rules.push(checkDuplicate);

      return _rules;
    },
    tenderIdRules(tender) {
      const _rules = [];

      if (this.isBlackBoardSaas && tender.type === TENDER_TYPES.MEAL_EXCHANGE) {
        _rules.push(rules.exactChars('Tender ID', 2));
      }

      return _rules;
    },
  },
  watch: {
    showBrandMealplanSetup(v) {
      if (!v) return;
      this.activeMealplanType = this.siteMealplanObject.type;
    },
    activeMealplanType: {
      async handler(newType, oldType) {
        if (newType === oldType) return;
        if (!newType) {
          if (this.showBrandMealplanSetup) {
            this.showBrandMealplanSetup = false;
          }
          return;
        }
        if (this.showBrandMealplanSetup) {
          const sitePublicMealplan = cloneDeep(this.siteMealplanObject);
          const sitePrivateMealplan = cloneDeep(this.sitePrivateMealplanObject);
          const siteMealplan = {
            ...this.mealplanObject,
            ...sitePublicMealplan,
          };
          if (this.isAtrium) {
            siteMealplan.public = sitePublicMealplan;
            siteMealplan.private = sitePrivateMealplan;
          }
          if (this.isCbord) {
            siteMealplan.private = sitePrivateMealplan || undefined;
          }
          await this.$emit('update:mealplan', [siteMealplan]);
          this.showBrandMealplanSetup = false;
        }
        if (this.isBrandConfig) {
          let { terminalId } = this;
          if (terminalId && typeof terminalId === 'string' && terminalId.includes(',')) {
            terminalId = terminalId.split(',');
          }
          const shouldOverwriteTerminalId = Array.isArray(terminalId);
          if (shouldOverwriteTerminalId) {
            // happens only on initial load, set default value of terminal_id
            // as it's being pulled from site to brand to first value
            const [firstTerminal] = terminalId;
            this.terminalId = firstTerminal;
          }
        }
        if (this.isCbord) {
          if (!this.mealplanObject.private) {
            // assign new object to mealplan
            this.$set(this.mealplanObject, 'private', cloneDeep(this.cbordTemplate.private));
            this.$delete(this.mealplanObject, 'public');
          }
          return;
        }
        if (this.isAtrium) {
          const { site_id, brand_id } = this.$route.params;
          const mealplanId = ID(
            'mealplan',
            'atrium',
            'mealplan',
            this.isBrandConfig ? `${site_id}:${brand_id || ''}` : site_id || '',
          );
          if (!this.mealplanObject.private || !this.mealplanObject.public) {
            // assign new object to mealplan
            this.$set(this.mealplanObject, 'private', cloneDeep(this.atriumTemplate.private));
            this.$set(this.mealplanObject, 'public', cloneDeep(this.atriumTemplate.public));
          }
          if (this.mealplanObject.id !== mealplanId) {
            this.$set(this.mealplanObject, 'id', mealplanId);
          }
          if (this.mealplanObject.private.id !== mealplanId) {
            this.$set(this.mealplanObject.private, 'id', mealplanId);
          }
          if (this.mealplanObject.public.id !== mealplanId) {
            this.$set(this.mealplanObject.public, 'id', mealplanId);
          }
          return;
        }
        if (this.isBlackBoard) {
          const { site_id, brand_id } = this.$route.params;
          const mealplanId = ID(
            'mealplan',
            'blackboard',
            'mealplan',
            this.isBrandConfig ? `${site_id}:${brand_id || ''}` : site_id || '',
          );
          if (
            this.isSiteConfig &&
            (!this.mealplanObject.private || !this.mealplanObject.private.keystore)
          ) {
            this.$set(this.mealplanObject, 'private', cloneDeep(this.blackboardTemplate.private));
          }
          if (this.mealplanObject.id !== mealplanId) {
            this.$set(this.mealplanObject, 'id', mealplanId);
          }
          if (this.isSiteConfig && this.mealplanObject.private.id !== mealplanId) {
            this.$set(this.mealplanObject.private, 'id', mealplanId);
          }
          return;
        }
        if (this.isItc) {
          /* new config */
          if (
            this.isSiteConfig &&
            (!this.mealplanObject.private || !this.mealplanObject.private.keystore)
          ) {
            this.$set(this.mealplanObject, 'private', cloneDeep(this.itcTemplate.private));
            this.mealplanObject.private.type = this.mealplanObject.type;
          }
          return;
        }
        if (this.isHeartland) {
          const { site_id } = this.$route.params;
          const mealplanId = ID('mealplan', 'heartland', 'mealplan', site_id);
          if (
            this.isSiteConfig &&
            (!this.mealplanObject.private || !this.mealplanObject.private.keystore)
          ) {
            this.$set(this.mealplanObject, 'private', cloneDeep(this.heartlandTemplate.private));
          }
          if (this.mealplanObject.id !== mealplanId) {
            this.$set(this.mealplanObject, 'id', mealplanId);
          }
          if (this.isSiteConfig && this.mealplanObject.private.id !== mealplanId) {
            this.$set(this.mealplanObject.private, 'id', mealplanId);
          }
          return;
        }
        this.$delete(this.mealplanObject, 'private');
        this.$delete(this.mealplanObject, 'public');
      },
      immediate: true,
    },
    // eslint-disable-next-line
    'mealplanObject.id': function(id) {
      if (this.isAtrium) {
        this.$set(this.mealplanObject, 'private', { ...this.mealplanObject.private, id });
      }
    },
    // eslint-disable-next-line
    'mealplanObject.name': function() {
      if (this.isItc && this.isSiteConfig) {
        this.mealplanObject.private.name = this.mealplanObject.name;
      }
    },
  },
  computed: {
    ...mapGetters('splitio', ['getFeatureFlags']),
    isTransactSaasFeatureFlagOn() {
      return this.getFeatureFlags['ap3-transact-saas-plan-types'];
    },
    isTransactPremiseFeatureFlagOn() {
      return this.getFeatureFlags['ap3-transact-premise-plan-types'];
    },
    isBrandMealplanMissing() {
      return this.isBrandConfig && !this.mealplanObject;
    },
    isMealplanConfigVisible() {
      return (
        this.isSiteConfig ||
        this.showBrandMealplanSetup ||
        (this.siteMealplanObject && !this.isBrandMealplanMissing)
      );
    },
    mealplanNote: {
      get() {
        if (!this.mealplanObject.note) this.$set(this.mealplanObject, 'note', {});
        return this.mealplanObject.note;
      },
      set(v) {
        this.$set(this.mealplanObject, 'note', v);
      },
    },
    mealplanObject: {
      get() {
        return (this.mealplan && this.mealplan[0]) || null;
      },
      set(value) {
        this.$emit('update:mealplan', [value]);
      },
    },
    activeMealplanType: {
      get() {
        return (this.mealplanObject && this.mealplanObject.type) || null;
      },
      set(newType) {
        if (!newType) {
          this.$emit('update:mealplan', []);
          return;
        }
        this.createMealplan(newType);
      },
    },
    isCbord() {
      return (
        this.activeMealplanType === this.MEALPLAN_TYPES.CBORD ||
        this.activeMealplanType === this.MEALPLAN_TYPES.CBORD_DIRECT
      );
    },
    isBlackBoard() {
      return this.isTypeBlackboard(this.activeMealplanType);
    },
    isAtrium() {
      return this.activeMealplanType === this.MEALPLAN_TYPES.ATRIUM;
    },
    isHeartland() {
      return this.activeMealplanType === this.MEALPLAN_TYPES.HEARTLAND;
    },
    isItc() {
      return this.isTypeItc(this.activeMealplanType);
    },
    isBlackBoardSaas() {
      return this.activeMealplanType === this.MEALPLAN_TYPES.BLACKBOARD_SAAS;
    },
    siteMealplanObject() {
      return (
        this.sitePublicConfig && this.sitePublicConfig.mealplan && this.sitePublicConfig.mealplan[0]
      );
    },
    sitePrivateMealplanObject() {
      return (
        this.sitePrivateConfig &&
        Array.isArray(this.sitePrivateConfig.mealplan) &&
        this.sitePrivateConfig.mealplan[0]
      );
    },
    isSiteConfig() {
      return this.configLevel === this.SITE;
    },
    isBrandConfig() {
      return this.configLevel === this.BRAND;
    },
    terminalIdRules() {
      const _rules = [];
      if (this.isBlackBoard || this.isItc) {
        if (this.allowMultipleTerminalIds) {
          _rules.push(
            rules.requiredArray('This is a required field'),
            rules.arrayOf(rules.numeric()),
          );
          if (this.isBlackBoardSaas) {
            _rules.push(rules.arrayOf(rules.exactChars('Terminal ID', 8)));
          }
        } else {
          _rules.push(...this.requiredField);
          if (this.isBlackBoardSaas) {
            _rules.push(rules.exactChars('Terminal ID', 8));
          }
        }
      } else if (this.isHeartland) {
        _rules.push(rules.requiredArray('This is a required field'));
      }
      return _rules;
    },
    terminalId: {
      get() {
        let _terminalId = this.mealplanObject.terminal_id;
        if (this.isAtrium) {
          _terminalId =
            this.mealplanObject.private &&
            this.mealplanObject.private.config &&
            this.mealplanObject.private.config.terminal_id;
        }
        if (this.isItc) {
          _terminalId = this.isSiteConfig
            ? this.mealplanObject.private.keystore.device_id
            : this.sitePrivateConfig.mealplan &&
              this.sitePrivateConfig.mealplan[0].keystore.device_id;
        }
        const shouldSplitIntoArray =
          (this.isAtrium || this.isBlackBoard || this.isHeartland) &&
          _terminalId &&
          this.isSiteConfig &&
          !Array.isArray(_terminalId);
        if (shouldSplitIntoArray) {
          _terminalId = _terminalId.split(',').filter(Boolean);
        }
        return _terminalId;
      },
      set(_terminalId) {
        const shouldConvertArrayToString =
          this.isSiteConfig && (this.isAtrium || this.isBlackBoard || this.isHeartland);
        if (shouldConvertArrayToString) {
          _terminalId = _terminalId.join(',');
        }
        if (this.isAtrium) {
          this.$set(this.mealplanObject.private.config, 'terminal_id', _terminalId);
          return;
        }
        if (this.isItc) {
          this.$set(this.mealplanObject.private.keystore, 'device_id', _terminalId);
          return;
        }
        this.$set(this.mealplanObject, 'terminal_id', _terminalId);
      },
    },
    siteTerminalIds() {
      let terminalIds = this.isItc
        ? this.sitePrivateConfig.mealplan[0].keystore.device_id
        : this.siteMealplanObject.terminal_id;
      if (this.isAtrium) {
        terminalIds =
          this.sitePrivateMealplanObject &&
          this.sitePrivateMealplanObject.config &&
          this.sitePrivateMealplanObject.config.terminal_id;
      }
      const shouldConvertToArray =
        (this.isAtrium || this.isBlackBoard || this.isHeartland) &&
        terminalIds &&
        !Array.isArray(terminalIds);
      if (shouldConvertToArray) {
        terminalIds = terminalIds.split(',');
      }
      return terminalIds;
    },
    tenders() {
      if (this.isAtrium) {
        return this.mealplanObject?.public?.tenders || [];
      }
      return this.mealplanObject.tenders;
    },
    tenderTypesProxy() {
      if (this.isAtrium) {
        return this.tenderTypes.filter((type) => type.value !== TENDER_TYPES.MEAL_EXCHANGE);
      }
      // Transact SaaS Feature Flag
      // Off hides MEQ and MX
      if (this.isBlackBoardSaas) {
        if (!this.isTransactSaasFeatureFlagOn) {
          return this.tenderTypes.filter((type) => type.value === TENDER_TYPES.DECLINING_BALANCE);
        }
      }
      // Transact Premise Feature Flag
      // off hides MEQ
      if (this.isBlackBoard) {
        if (!this.isTransactPremiseFeatureFlagOn) {
          return this.tenderTypes.filter((type) => type.value !== TENDER_TYPES.MEAL_EQUIVALENT);
        }
      }
      if (!this.isCbord && !this.isBlackBoard) {
        return this.tenderTypes.filter((type) => type.value === TENDER_TYPES.DECLINING_BALANCE);
      }

      return this.tenderTypes;
    },
    tenderNameRules() {
      const _rules = [];
      if (this.isAtrium || this.isBlackBoard) {
        _rules.push(...this.requiredField);
      }
      return _rules;
    },
    shouldAllowRemoveTender() {
      return this.tenders.length > 1 && !this.isSectionDisabled;
    },
    isDomainManagementVisible() {
      return this.isSiteConfig && (this.isAtrium || this.isBlackBoard);
    },
    isPlanNameDisabled() {
      return this.isSectionDisabled || (this.isBrandConfig && this.isAtrium);
    },
    allowMultipleTerminalIds() {
      return !this.isItc;
    },
    getPlanName() {
      return this.mealplanObject.name;
    },
    getHeartlandTenderTaxExempt() {
      return this.mealplanObject?.tenders?.[0]?.tax_exempt;
    },
    isBrandMEQ() {
      return this.mealplanObject.isBrandMEQ;
    },
    hasMEQTenders() {
      if (this.mealplanObject.tenders) {
        return this.mealplanObject.tenders.some(
          (tender) => tender.type === TENDER_TYPES.MEAL_EQUIVALENT,
        );
      }
      return false;
    },
    isBrandMX() {
      return this.mealplanObject.isBrandMX;
    },
    hasMXTenders() {
      if (this.mealplanObject.tenders) {
        return this.mealplanObject.tenders.some(
          (tender) => tender.type === TENDER_TYPES.MEAL_EXCHANGE,
        );
      }
      return false;
    },
    tenderMenuTax: {
      get() {
        return this.meal_equivalent_tender_edit.tax_exempt;
      },
      set(isTaxExempt) {
        this.meal_equivalent_tender_edit.tax_exempt = isTaxExempt;
        if (this.isBlackBoard || this.isCbord) {
          this.mealplanObject.tenders[
            this.meal_equivalent_tender_edit.index
          ].tax_exempt = isTaxExempt;
        } else {
          this.mealplanObject.public.tenders[
            this.meal_equivalent_tender_edit.index
          ].tax_exempt = isTaxExempt;
        }

        this.$emit('update:mealplan', [this.mealplanObject]);
      },
    },
  },
};
</script>

<style scoped>
/*
    Domain Dialog
*/
.Domain-Emails-Content {
  margin-top: 18px;
  width: 300px;
}
.Domain-Emails-Link {
  text-decoration: underline;
  margin-bottom: 8px;
  cursor: pointer;
}
.Domain-Dialog-Content {
  padding-right: 14px;
  padding-left: 14px;
}
.Domain-Dialog-List {
  height: 325px;
  width: 100%;
  overflow: scroll;
  border: solid 1px #757575;
}
.Domain-Cell {
  width: 100%;
  height: 48px;
  border-radius: 1px;
  border-bottom: solid 1px #757575;
}
.Loyalty-Description-Messages {
  padding-top: 14px;
}
</style>
